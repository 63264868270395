// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popupReport {
  position: absolute;
  z-index: 999;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  padding: 10px;
  width: -webkit-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  font-size: 14px;
  line-height: 1.5;
  color: #333;
}

.popupReport h3 {
  margin-top: 0;
}

.popupReport p {
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/admin/adminReports/adminReports.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,YAAA;EACA,sBAAA;EACA,sBAAA;EACA,kBAAA;EACA,0CAAA;EACA,aAAA;EACA,0BAAA;EAAA,kBAAA;EACA,2BAAA;EAAA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;AACJ;;AAEE;EACE,aAAA;AACJ;;AAEE;EACE,gBAAA;AACJ","sourcesContent":[".popupReport {\n    position: absolute;\n    z-index: 999;\n    background-color: #fff;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);\n    padding: 10px;\n    width: fit-content;\n    height: fit-content;\n    font-size: 14px;\n    line-height: 1.5;\n    color: #333;\n  }\n  \n  .popupReport h3 {\n    margin-top: 0;\n  }\n  \n  .popupReport p {\n    margin-bottom: 0;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
