import { gql } from "apollo-boost";

const allAreasQuery = gql`
  
    query(
    $view: ID
    $site: ID
  ) { 
    allAreas (
      view: $view
      site: $site
    ) {
        _id
      testingDiagrams
      testingDiagram
      createdBy
      areaNumber
      diagramImage
      view
      site
      pass
      name
      diagramPhoto
      pendulum
      floorType
      floorTypeDescription
      floorTexture
      floorTextureDescription
      floorFinish
      floorFinishDescription
      conditionofFloorFinish
      slopeofFloorsSurface
      cleaningChemicalsUsed
      slsWaterConcentration
      dcof
      type
      auditorsNotes
      images
      createdAt
      results
      testingCondition
      testingCategory
      load
    }
  }
  
`;

export default allAreasQuery;
