// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modalSideBar {
  border-right: 2px solid rgb(230, 227, 227);
  width: 20%;
  padding: 0 1rem !important;
  margin: 0 1rem;
  overflow: auto;
}

.versionCardContainer {
  min-height: 60px;
  width: 100%;
  display: flex;
  margin: 0.3rem 0;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.versionCard {
  width: 100%;
  height: 100%;
  margin-bottom: 0px !important;
}

.selectedHistory {
  border: 2px solid #5C92BF !important;
}

.historyViewerContainer {
  border: 2px solid rgb(230, 227, 227);
  overflow: auto;
  width: 79%;
}`, "",{"version":3,"sources":["webpack://./src/components/resuable/modals/templateHistoryModal/templateHistoryModal.scss"],"names":[],"mappings":"AAAA;EACI,0CAAA;EACA,UAAA;EACA,0BAAA;EACA,cAAA;EACA,cAAA;AACJ;;AACA;EACI,gBAAA;EACA,WAAA;EACA,aAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AAEJ;;AAAA;EACI,WAAA;EACA,YAAA;EACA,6BAAA;AAGJ;;AADA;EACI,oCAAA;AAIJ;;AAFA;EACI,oCAAA;EACA,cAAA;EACA,UAAA;AAKJ","sourcesContent":[".modalSideBar {\n    border-right: 2px solid rgb(230, 227, 227);\n    width: 20%;\n    padding: 0 1rem !important;\n    margin: 0 1rem;\n    overflow: auto;\n}\n.versionCardContainer {\n    min-height: 60px;\n    width: 100%;\n    display: flex;\n    margin: .3rem 0;\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n}\n.versionCard {\n    width: 100%;\n    height: 100%;\n    margin-bottom: 0px !important;\n}\n.selectedHistory {\n    border: 2px solid #5C92BF !important;\n}\n.historyViewerContainer {\n    border: 2px solid rgb(230, 227, 227);\n    overflow: auto;\n    width:79%\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
