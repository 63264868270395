// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.watermark {
  display: none;
  top: 50vh;
  z-index: 9;
  width: 50vw;
  page-break-after: always;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.1;
}

table.report-container {
  page-break-after: always;
  width: 100%;
}

thead.report-header {
  display: table-header-group;
}

tfoot.report-footer {
  display: table-footer-group;
}

div.footer-info, div.page-footer {
  display: none;
}

div.header-info, div.page-header {
  display: none;
}

.avoid {
  page-break-inside: avoid !important;
  margin: 4px auto !important; /* to keep the page break from cutting too close to the text in the div */
}

@media print {
  .watermark {
    display: block;
    counter-increment: page;
    position: fixed;
  }
  div.page-footer, div.footer-info {
    display: block;
    margin-bottom: 20px;
    font-weight: bold;
  }
  div.page-header, div.header-info {
    margin-bottom: 10px;
    margin-top: 0px;
    display: block;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/resuable/modals/printModal/index.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,SAAA;EACA,UAAA;EACA,WAAA;EACA,wBAAA;EACA,SAAA;EACA,gCAAA;EACA,YAAA;AADJ;;AAIA;EACI,wBAAA;EACA,WAAA;AADJ;;AAIA;EACI,2BAAA;AADJ;;AAIA;EACI,2BAAA;AADJ;;AAIA;EACI,aAAA;AADJ;;AAGA;EACI,aAAA;AAAJ;;AAGA;EACI,mCAAA;EACA,2BAAA,EAAA,yEAAA;AAAJ;;AAGA;EACI;IACI,cAAA;IACA,uBAAA;IACA,eAAA;EAAN;EAGE;IACI,cAAA;IACA,mBAAA;IACA,iBAAA;EADN;EAGE;IACI,mBAAA;IACA,eAAA;IACA,cAAA;EADN;AACF","sourcesContent":["\n\n.watermark {\n    display: none;\n    top: 50vh;\n    z-index: 9;\n    width: 50vw;\n    page-break-after: always;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    opacity: .1;\n}\n\ntable.report-container {\n    page-break-after: always;\n    width: 100%;\n}\n\nthead.report-header {\n    display: table-header-group;\n}\n\ntfoot.report-footer {\n    display: table-footer-group;\n}\n\ndiv.footer-info, div.page-footer {\n    display: none;\n}\ndiv.header-info, div.page-header {\n    display: none;\n}\n\n.avoid {\n    page-break-inside: avoid !important;\n    margin: 4px auto !important;  /* to keep the page break from cutting too close to the text in the div */\n  }\n\n@media print {\n    .watermark {\n        display: block;\n        counter-increment: page;\n        position: fixed;\n    }\n\n    div.page-footer, div.footer-info {\n        display: block;\n        margin-bottom: 20px;\n        font-weight: bold;\n    }\n    div.page-header, div.header-info {\n        margin-bottom: 10px;\n        margin-top: 0px;\n        display: block;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
