import { gql } from "apollo-boost";

const updateAreaMutation = gql`
  mutation(
    $_id:ID,
    $user:ID,
    $floorType:String,
    $name:String,
    $areaNumber: String
    $diagramPhoto: String,
    $pendulum: String,
    $floorTypeDescription:String,
    $floorTexture:String,
    $floorTextureDescription:String,
    $floorFinish:String,
    $results:String,
    $floorFinishDescription:String,
    $conditionofFloorFinish:String,
    $slopeofFloorsSurface:String,
    $cleaningChemicalsUsed:String,
    $slsWaterConcentration:String,
    $dcof:String,
    $testingDiagram:String,
    $diagramImage:String,
    $auditorsNotes:String,
    $testingCondition:String,
    $testingCategory:String,
    $images: [String],
    $reset: Boolean
    $resetTime: Boolean
    $resetFirst: Boolean

  ) {
    updateArea(
    diagramImage:$diagramImage
    _id:$_id
    user:$user
    areaNumber: $areaNumber
    diagramPhoto: $diagramPhoto
    pendulum: $pendulum
    reset: $reset
    resetTime: $resetTime
    floorType:$floorType
    name:$name
    results:$results
    floorTypeDescription:$floorTypeDescription
    floorTexture:$floorTexture
    floorTextureDescription:$floorTextureDescription
    floorFinish:$floorFinish
    floorFinishDescription:$floorFinishDescription
    conditionofFloorFinish:$conditionofFloorFinish
    slopeofFloorsSurface:$slopeofFloorsSurface
    cleaningChemicalsUsed:$cleaningChemicalsUsed
    slsWaterConcentration:$slsWaterConcentration
    dcof:$dcof
    testingDiagram:$testingDiagram
    auditorsNotes:$auditorsNotes    
    testingCondition:$testingCondition
    testingCategory:$testingCategory
    images: $images
    resetFirst: $resetFirst

    ) {
    _id
    diagramPhoto
    pendulum
    testingCondition
    testingCategory
    testingDiagrams
    testingDiagram
    diagramImage
    view
    results
    pass
    floorType 
    areaNumber
    name
    floorTypeDescription
    floorTexture
    floorTextureDescription
    floorFinish 
    floorFinishDescription
    conditionofFloorFinish
    slopeofFloorsSurface
    cleaningChemicalsUsed
    slsWaterConcentration 
    dcof
    auditorsNotes  
    images
    }
  }
`;

export default updateAreaMutation;
 