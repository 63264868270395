import { gql } from "apollo-boost";

const viewQuery = gql`
  query(
    $_id: ID
  ) {
    view(
      _id: $_id
     
    ) {
    _id
    project
    default
    firstTime
    areas
    locations
    passed
    failed
    reports
    createdAt
    allReports
    label
    tileResult {
      first
      firstAfter
      second
      secondAfter
      exist
      result
      pass
      passAfter
      dcof
    }
    }
  }
`;

export default viewQuery;
