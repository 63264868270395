import React, { useEffect, useRef, useState } from 'react'
import Modal from "react-modal"

import { faDownload, faEraser, faPaintBrush, faTimes, faTrash, faUndoAlt, faInfo } from '@fortawesome/free-solid-svg-icons';

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import Dropdown from "react-dropdown";
import { DataSpinner } from '../../spinner/dataSpinner';
import AvatarModal from './avatarModal';
// import CanvasDraw from "react-canvas-draw";
import LZString from 'lz-string';
import archive from "../../../../assests/icons/archive.svg"
import Planner from './diagramWrapper';
import { reset, saveDiagram, useShapes } from './diagramWrapper/state';
import InfoItem from '../../info'; 
import infoI from "../../../../assests/icons/info.svg"
import ReactTooltip from 'react-tooltip';
import SaveDraftModal from '../saveDraftModal';
import { useDispatch } from 'react-redux';
import ACTION_INFO_ACTIVE from '../../../../actions/InfoActive/ACTION_INFO_ACTIVE';
import { UncontrolledTooltip } from 'reactstrap';
import UploadPhotoModal from '../uploadPhotoModal';
import html2canvas from "html2canvas"
import Multiselect from 'multiselect-react-dropdown';

const AddAreaModal = (props) => {
  const dispatch = useDispatch()

  const [progress, setProgress] = useState(0)

  const canvasRef = useRef() 

    const {
        pendulum,
        areaId,
        setAddAreaDropDown,
        history,
        haveData,
        setHaveData,
        setHistory,
        uploadFile,
        areaNameError,
        setAreaNameError,
        areas,
        updateAreaImages,
        addAreaModal,
        setAddAreaModal,
        lines,
        setLines,
        setAreaName,
        areaName,
        setFloorType,
        setTestingCondition,
        testingCondition,
        setTestingCategory,
        testingCategory,
        floorType,
        setFloorTypeDescription,
        floorTypeDescription,
        setTextureType,
        TextureType,
        setTextureTypeDescription,
        TextureTypeDescription,
        setFloorFinish,
        floorFinish,
        setFinishCondition,
        setFloorFinishCondition,
        floorFinishCondition,
        finishCondition,
        setFloorFinishDescription,
        floorFinishDescription,
        setslopeSurface,
        slopeSurface,
        setcleaningChemicals,
        cleaningChemicals,
        setSlsWaterConcentration,
        slsWaterConcentration,
        setAverageDcof,
        averageDcof,
        setAreaNotes,
        areaNotes,
        areaModalPage,
        setAreaModalPage,
        addAreaFunc,
        // area images
        images,
        setImages,
        takeAPhotoSelected,
        changeTakeAPhotoSelected,
        webcamURI,
        changeWebcamURI,
        imageUploaded,
        changeImageUploaded,
        imagePreviewAvailable,
        changeImagePreviewAvailable,
        handleDeletedPreviewImage,
        handleImageUploaded,
        addProfilePhotoClicked,
        setAddProfileClicked,
        loadAreaFunc, 
        // testing diagram
        testingDiagram,
        setTestingDiagram,
        diagramImage,
        setDiagramImage,
        updateAreaFunc,
        selectedArea,
        setSelectedArea,
        setNewDiagramImage,
        areaLines,
        setAreaLines,
        areaDiagram,    
        draftAreaFunc,
        setLoadAreaModal,
        areaNumber,
        setAreaNumber,
        photo,
        setPhoto,
        imagesLater,
        setImagesLater,
        addLocationToDeleted,
        setIRHD,
        IRHD,
    } = props


    const onSelectFunc = (selectedList, selectedItem) => {

      setIRHD(selectedList)
   }
   
   const onRemoveFunc = (selectedList, removedItem) => {

    setIRHD(selectedList)
   }


    const [LoadingShape, setLoadingShape] = useState(false)
    const [loadingCanvas, setLoadingCanvas] = useState(false)
    const [areaNameTyping, setAreaNameTyping] = useState(null)
    useEffect(() => {
      if(areaName) {
        setAreaNameTyping(areaName)
      } else {
        setAreaNameTyping("")
      }
    },[areaName])

    const [linesPending, setLinesPending] = useState([null])
    useEffect(() => {
      if(lines) {
        setLinesPending(lines)
      } else {
        setLinesPending("")
      }
    },[lines])

    const [diagramImagePending, setDiagramImagePending] = useState([null])
    useEffect(() => {
      if(diagramImage) {
        setDiagramImagePending(diagramImage)
      } else {
        setDiagramImagePending("")
      }
    },[diagramImage])

    const [newDiagramImagePending, setNewDiagramImagePending] = useState([null])

    const [areaNotesTyping, setAreaNotesTyping] = useState(null)

    useEffect(() => {
      if(areaNotes) {
        setAreaNotesTyping(areaNotes)
      } else {
        setAreaNotesTyping("")
      }
    },[areaNotes])

    const [floorTypeTyping, setFloorTypeTyping] = useState(null)
    useEffect(() => {
      if(floorType) {
        setFloorTypeTyping(floorType)
      } else {
        setFloorTypeTyping("")
      }
    },[floorType])

    const [floorFinishTyping, setFloorFinishTyping] = useState(null)
    useEffect(() => {
      if(floorFinish) {
        setFloorFinishTyping(floorFinish)
      } else {
        setFloorFinishTyping("")
      }
    },[floorFinish])


    const [floorTypeDescriptionTyping, setFloorTypeDescriptionTyping] = useState(null)
    useEffect(() => {
      if(floorTypeDescription) {
        setFloorTypeDescriptionTyping(floorTypeDescription)
      } else {
        setFloorTypeDescriptionTyping("")
      }
    },[floorTypeDescription])

    const [floorFinishDescriptionTyping, setFloorFinishDescriptionTyping] = useState(null)
    useEffect(() => {
      if(floorFinishDescription) {
        setFloorFinishDescriptionTyping(floorFinishDescription)
      } else {
        setFloorFinishDescriptionTyping("")
      }
    },[floorFinishDescription])

    // canvas diagram
    const [canvasEraser, setCanvasEraser] = useState(false)


    const [otherFloorType, setOtherFloorType] = useState(false)
    const [otheAreaSize, setOtherAreaSize] = useState(false)
    const [areaSize, setAreaSize] = useState("")
    const floorTypeOptions = ["Ceramic", "Concrete", "Granite", "Limestone", "Linoleum", "Luxury Vinyl Tile (LVT)", "Marble", "Mosaic", "Porcelain", "Quarry", "Rubber", "Sandstone", "Slate", "Terrazzo", "Terracotta", "Travertine", "Vinyl Composite Tile (VCT)", "Wood", "Other"]
    const floorFinishOptions = ["None", "Epoxy", "Oil-Based Polyurethane", "Paint", "Polished", "Water-Based Polyurethane", "Wax", "Unknown", "Other"]
    const shapes = useShapes((state) => Object.entries(state.shapes));

const [floorTypeDescriptionActive, setFloorTypeDescriptionActive] = useState(false) 

const [finishConditionDescriptionActive, setFinishConditionDescriptionActive] = useState(false)

const [otherFloorFinish, setOtherFloorFinish] = useState(false)

const [draftModal, setDraftModal] = useState(null)
const noHandleClick = () => {
  setAddAreaDropDown(true)
  setAddAreaModal(null)
  addAreaModal.includes("Load") && setLoadAreaModal("Area")

  if(true) {
    useShapes.set({
    selected:null,
    shapes:{},
    lines:[] 
  })
  setLines && setLines([])
  changeWebcamURI("");
  setAreaNameError(null)
  changeImagePreviewAvailable(
    false
  );
  changeImagePreviewAvailable(
    false
  );
  
  changeTakeAPhotoSelected(false);
  changeImageUploaded("");
  setImages([])
  setAreaNumber("")
  setFloorType("")
  setTestingCondition("")
  setTestingCategory("")
  setTestingCategory("")
  setAreaName("")
  setFloorTypeDescription("")
  setTextureType("")
  setTextureTypeDescription("")
  setFloorFinish("")
  setFinishCondition("")
  setFloorFinishDescription("")
  setslopeSurface("")
  setcleaningChemicals("")
  setSlsWaterConcentration("")
  setAverageDcof("")
  setAreaNotes("")
  setCanvasEraser(false)
  setTestingDiagram(null)
  setSelectedArea(null)
  setDiagramImage("")
  setAreaModalPage(0)
  setHistory([])
  setHaveData && setHaveData(false)
  }
}

const [areaNumberError, setAreaNumberError] = useState("")
const isExist = (str) => {
  return areas.some(x => x.name.toLowerCase().replace(/\s/g, '') == str);
}

const isExistAreaNumber = (str) => {
  return areas.some(x => x.areaNumber == str);
}

const validateInput = (e) => {
  if (isExist(e.target.value.toLowerCase().replace(/\s/g, ''))) {
    setAreaNameError("Area name already exist")
  } else {
   setAreaNameError(null)
  }  
}
const validateAreaNumberInput = (e) => {
  if (isExistAreaNumber(e.value)) {
    setAreaNumberError("Area number already exist")
  } else {
    setAreaNumberError("")
    setAreaNumber(e.value)
  }  
}


useEffect(() => {
  if(floorFinish) {
    if(!floorFinishOptions.includes(floorFinish)) {
      setOtherFloorFinish(true)
    }
  }
},[floorFinish])



useEffect(() => {
  if(floorFinishDescriptionTyping) {
    setFloorTypeDescriptionActive(true)
  }
},[floorTypeDescriptionTyping])

useEffect(() => {
if(floorFinishDescriptionTyping) {
  setFinishConditionDescriptionActive(true)
}
},[floorFinishDescriptionTyping])


const [uploadPhotoModal, setUploadPhotoModal] = useState(false)


useEffect(() => {
  if(addAreaModal == "Area") {
    setAreaModalPage(-1)
  } else {
    setAreaModalPage(0)
  }
},[addAreaModal])
return(
<Modal
        isOpen={addAreaModal ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center",  
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"18px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >       
         
              {addAreaModal && !addAreaModal.includes("photos") ? (
               <div className="modal task-modal" style={{height:"fit-content", width: addAreaModal.includes("Load") && areaModalPage == 0 ? "auto" : "44vw", justifyContent:"space-between"}}>
                {loadingCanvas ? <DataSpinner over /> : null}
                 <UploadPhotoModal 
                  active={uploadPhotoModal}
                  setActive={setUploadPhotoModal}
                  photo={photo}
                  setPhoto={setPhoto}
                 />
                 <SaveDraftModal 
                 areaId={areaId}
                 setAddAreaDropDown={setAddAreaDropDown}
                 draftModal={draftModal}
                 setDraftModal={setDraftModal}
                 setAreaModal={setAddAreaModal}
                 draftAreaFunc={draftAreaFunc}
                 noHandleClick={noHandleClick}
                 />
                  <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {                  
                  addAreaModal.includes("Edit") && !addAreaModal.includes("Load") ? noHandleClick() : areaModalPage !== -1 ? 
                  setDraftModal("Area")  : noHandleClick()
                }}
              />  
          <h1 className="header" style={{width:"100%", position:"fixed", top:0, left:0, right:0, width:"100%", height:"33px"}}><span>
          {addAreaModal.includes("Edit") ? "Edit Area" : `Add ${addAreaModal}`}
          <div data-for={"main"} style={{display:"inline-block"}}><img id="UncontrolledTooltipExample_7" onClick={() => dispatch(ACTION_INFO_ACTIVE("Determine the area(s) to be tested based on the test objective. An area is a contiguous space with hard surface flooring of the same or similar type. Before testing, you will enter a unique area identification code (AID) for that specific Area which corresponds with that particular test area (for example, the lobby is Area 001 (A001) but the bathroom is Area 002 (A002)). If you have already completed a slip test using the BOT-3000E select the AID (001, 002…) that matches the Area # that you selected previously on the BOT-3000E."))} width={15} style={{cursor:"pointer",
              color:"#5C92BF", margin:"0 10px"}} src={infoI} />
              <UncontrolledTooltip
    placement="top"
    target="UncontrolledTooltipExample_7"
  >
    Need Help!
  </UncontrolledTooltip>
              </div>
          </span> </h1>        
          <div style={{height:"100%", margin:"50px 0", justifyContent:"flex-start", overflowY:"auto"}} className="form-group-container task-form">
          { true ? 
          <>
          { areaModalPage == -1 ? (

            <div className="form-row" style={{flexDirection:"column"}}>
            <div className="form-collection" style={{width:"100%", fontWeight:"bold"}}>
              Now you are going to add an Area 
            </div>
            <div className="form-collection" style={{width:"100%"}}>
            - An Area is a contiguous space of the same or similar floor type.
            </div> 
            <div className="form-collection" style={{width:"100%"}}>
            - You will give the Area a name <span style={{fontStyle:"italic"}}>(to differentiate it from other Areas in the Audit)</span>.
            </div> <div className="form-collection" style={{width:"100%"}}>
            - You will assign a number called an Area Identification number or AID, which is unique to this Area and is the same as the Area number chosen on the BOT-3000E.
            </div> <div className="form-collection" style={{width:"100%"}}>
            - You will be asked to create a Testing Diagram of this Area.
            </div> <div className="form-collection" style={{width:"100%"}}>
            - You will be asked to add a photo of each Area.
            </div> <div className="form-collection" style={{width:"100%"}}>
            - You will be asked to classify the characteristics of this Area according to the Product Use Classification categories.
            </div> 
            </div>
          ) : areaModalPage == 0 ? (
             <div style={{display:"flex", flexDirection:"row", width:"100%",height:"fit-content", justifyContent:"center", flexWrap:"wrap"}}>
             <div style={{ width: addAreaModal.includes("Load") ? "50%" : "100%", marginBottom:"50px", width:"600px"}}>
             <div className="form-row">
              <div className="form-collection" style={{width:"100%"}}>
              <div className="form-label" style={{justifyContent:"space-between", display:"flex", color:"black", textTransform:areaNameError? "none" : "capitalize"}}><div><span>Area Name<span style={{color:"red"}}> *</span></span></div>
                <div style={{display:"flex", color:"#EF1111"}}>{areaNameError && <div style={{color: areaNameError ? "#EF1111" : "black" }}>{areaNameError}</div> }<div id="UncontrolledTooltipExample_2" data-for={"main"}><img onClick={() => dispatch(ACTION_INFO_ACTIVE("The Area Name is a tool used by the auditor to “identify” the Area Identification (AID) codes with words. For example: If the auditor slip tests 3 areas (001, 002, 003), these AID’s can then be identified as the Men’s Restroom, the Women’s Restroom, and the Kitchen Area. This process makes it easier to identify or match the AID’s with actual rooms where testing took place on site."))} width={15} style={{cursor:"pointer",
                color:"#5C92BF", margin:"0 10px"}} src={infoI} /><UncontrolledTooltip
                  placement="top"
                  target="UncontrolledTooltipExample_2"
                >
                  Need Help!
                </UncontrolledTooltip></div></div>
              </div>
              <input

                onKeyUp={(e) => validateInput(e)}
                // onKeyDown={(e) => keyTyping(e)}
                onBlur={(e) => setAreaName(areaNameTyping)}
                onChange={(e) => setAreaNameTyping(e.target.value)}
                value={areaNameTyping}
                type="text" 
                placeholder="Area Name"
                className="form-input"
              />
            </div> 
            </div>
            {<div className="form-row">
            <div className="form-collection"id='UncontrolledTooltipExample_502909310' style={{width:"100%"}}>
              <div className="form-label" style={{justifyContent:"space-between", display:"flex", color:"black", textTransform: areaNumberError? "none" : "capitalize"}}><div>{pendulum ? "Area #": "Area Identification (AID)"}</div>
              <div style={{display:"flex", color:"#EF1111"}}>{areaNumberError && <div style={{color: areaNumberError ? "#EF1111" : "black" }}>{areaNumberError}</div>}<div data-for={"main"}><img id="UncontrolledTooltipExample_3" onClick={() => dispatch(ACTION_INFO_ACTIVE("AID codes or “areas” are used to help identify the physical area that you are testing within buildings, facilities, or work sites, etc. These “areas” are the specific “room(s)” where the slip testing will take place within a site. A single audit cannot have repeat AID’s, so the user must change the AID on the BOT-3000E when they move between areas or “rooms”. For example, if you test 3 “areas” you must have 3 different AID codes (001, 002, 003)."))} width={15} style={{cursor:"pointer",
              color:"#5C92BF", margin:"0 10px"}} src={infoI} /><UncontrolledTooltip
    placement="top"
    target="UncontrolledTooltipExample_3"
  >
    Need Help!
  </UncontrolledTooltip></div></div>
              </div>
              <Dropdown 
              disabled={haveData}
              options={["001", "002", "003", "004", "005", "006", "007", "008", "009", "010", "011", "012", "013", "014", "015", "016", "017", "018", "019", "020", "021", "022", "023", "024", "025", "026", "027", "028", "029", "030", "031", "032", "033", "034", "035", "036", "037", "038", "039", "040", "041", "042", "043", "044", "045", "046", "047", "048", "049", "050", "051", "052", "053", "054", "055", "056", "057", "058", "059", "060", "061", "062", "063", "064", "065", "066", "067", "068", "069", "070", "071", "072", "073", "074", "075", "076", "077", "078", "079", "080", "081", "082", "083", "084", "085", "086", "087", "088", "089", "090", "091", "092", "093", "094", "095", "096", "097", "098", "099", "100"]}
                            controlClassName="form-dropdown" 
                  value={areaNumber}
                  onChange={(choice) =>
                   validateAreaNumberInput(choice)
                  }
              placeholder={`Choose ${pendulum ? "Area #": "AID"} from 001 to 100`}
              /> 
              {haveData ? <UncontrolledTooltip
                  placement="top"
                  target="UncontrolledTooltipExample_502909310"
                >
                  AID can only be changed if you reset the data.
                  To reset the data, go to "Run Test" and edit the Product Use Category.
                </UncontrolledTooltip> : null}
            </div> 
            </div>}
            {true && <div className="form-row">
                <div className="form-collection textarea" style={{width:"100%"}}>
                <div style={{opacity: imagesLater ? .3 : 1}}> <div className="form-label" style={{justifyContent:"space-between"}}>
                      <span>Area Photos {!imagesLater ? <span style={{color:"red"}}> *</span> : null} {pendulum ? <span style={{color:"red"}}>{"(minimum 2 photos required)"}</span> : null}
                      </span>
                      
                      <div style={{display:"flex", color:"#EF1111"}}><div data-for={"main"}><img id="UncontrolledTooltipExample_400"  width={15} style={{cursor:"pointer",
                    color:"#5C92BF", margin:"0 10px"}} src={infoI} /><UncontrolledTooltip
                    placement="top"
                    target="UncontrolledTooltipExample_400"
                    >
                    Need Help!
                    </UncontrolledTooltip></div></div></div>
                    <AvatarModal
                    pendulum={pendulum}
                    imagesLater={imagesLater}
                    setImagesLater={setImagesLater}
                    progress={progress} 
                    setProgress={setProgress} 
                    images={images}
                    setImages={setImages}
                    takeAPhotoSelected={takeAPhotoSelected}
                    changeTakeAPhotoSelected={changeTakeAPhotoSelected}
                    webcamURI={webcamURI}
                    changeWebcamURI={changeWebcamURI}
                    imageUploaded={imageUploaded}
                    changeImageUploaded={changeImageUploaded}
                    imagePreviewAvailable={imagePreviewAvailable}
                    changeImagePreviewAvailable={changeImagePreviewAvailable}
                    handleDeletedPreviewImage={handleDeletedPreviewImage}
                    handleImageUploaded={handleImageUploaded}
                    addProfilePhotoClicked={addProfilePhotoClicked}
                    setAddProfileClicked={setAddProfileClicked}
                  /></div>
                 {!pendulum ? <label onClick={() => {
                    setImages([])
                    setImagesLater(!imagesLater)
                  }} className="toggle-control" style={{width:"100%",cursor:"pointer",justifyContent:"flex-end", margin:"10px 0px"}}>
                      <div style={{marginRight:"10px"}}>Upload Photos Later</div>
                  <span className={`${imagesLater ? "checked" : "notChecked"}`}></span>
                  <span className="control"></span>
              </label> : null}
            </div>
            
            </div>} 
            </div>
            <div style={{display:"flex", alignItems:"center", width:"40px", justifyContent:"center"}}>{addAreaModal.includes("Load") ?  <div className='verticalSeparatorLeft' style={{borderLeft:"1px solid white", height:"90%"}}/> : null }</div>

            { addAreaModal.includes("Load") ? <><div className="form-row" style={{maxWidth:"600px"}}>
  
<div className="form-collection" style={{width:"100%"}}>
<div className="form-row" style={{flexDirection:"column"}}>
<div className="form-collection" style={{width:"100%", fontWeight:"bold"}}>
<div className="form-label" style={{fontWeight:"bold"}}><span>Testing diagram<span style={{color:"red"}}> *</span></span><div onClick={() => setUploadPhotoModal("Diagram image")} style={{color:"#6DB240", cursor:"pointer"}}>
                upload floor plan
             </div></div>
  Draw diagram or upload floor plan of test area 
</div>
<div className="form-collection" style={{width:"100%"}}>
- Drag icons for replacement.
</div> 
<div className="form-collection" style={{width:"100%"}}>
- Double click to edit (move, resize, or delete).
</div>
</div>
<div style={{marginTop:"10px", width:"100%"}}><Planner
                    setLoadingShape={setLoadingShape}
                    history={history}
                    setHistory={setHistory}
                    photo={photo}
                    lines={linesPending}
                    setLines={setLinesPending}
                    diagramImage={diagramImagePending}
                    setDiagramImage={setDiagramImagePending}
                    reset={reset}
                    setNewDiagramImage={setNewDiagramImagePending}
                    areaLines={areaLines?.lines || []}
                    areaShapes={areaLines?.shapes || []}
                    locationMode={false} 
                    editArea={true}
                    /></div></div></div></> : null }
            </div>

          ) : areaModalPage == 1 ? (
             <>
             <div className="form-row" style={{flexDirection:"column"}}>
            <div className="form-collection" style={{width:"100%", fontWeight:"bold"}}>
            <div className="form-label" style={{fontWeight:"bold"}}><span>Testing diagram<span style={{color:"red"}}> *</span></span><div onClick={() => setUploadPhotoModal("Diagram image")} style={{color:"#6DB240", cursor:"pointer"}}>
                upload floor plan
             </div></div>
              Draw diagram or upload floor plan of test area 
            </div>
            <div className="form-collection" style={{width:"100%"}}>
            - Drag icons for replacement.
            </div> 
            <div className="form-collection" style={{width:"100%"}}>
            - Double click to edit (move, resize, or delete).
            </div>
            </div>
             <div className="form-row" style={{alignItems:"center", width:"100%"}}>
             <div className="form-collection relative" style={{width:"100%"}}>
             
             <div style={{minHeight:"350px", minWidth:"350px"}}>
             {!addAreaModal.includes("Edit") ? <Planner 
             setLoadingShape={setLoadingShape}
              photo={photo}
              lines={linesPending}
              setLines={setLinesPending}
              diagramImage={diagramImagePending}
              setDiagramImage={setDiagramImagePending}
              reset={reset} 
              history={history}
              setHistory={setHistory}
              />
              : !addAreaModal.includes("Load") ?
              <Planner
              setLoadingShape={setLoadingShape}
              history={history}
              setHistory={setHistory}
              photo={photo}
              lines={linesPending}
              setLines={setLinesPending}
              diagramImage={diagramImagePending}
              setDiagramImage={setDiagramImagePending}
              reset={reset}
              setNewDiagramImage={setNewDiagramImagePending}
              areaLines={areaLines?.lines || []}
              areaShapes={areaLines?.shapes || []}
              locationMode={false} 
              editArea={true}
              /> : null
          }
              </div> 
             </div>
             </div>
             </>
             ) : areaModalPage == 2 ? ( 
          <>        
          <div className="form-row">
            <div className="form-collection" id='UncontrolledTooltipExample_50290930' style={{width:"80%"}}>
              <div className="form-label"> 
                <span>{pendulum ? "Area Type" : "Product Use Category"}<span style={{color:"red"}}> *</span></span>
                <img id="UncontrolledTooltipExample_5"
                onClick={() => dispatch(ACTION_INFO_ACTIVE(<div style={{display:"flex", justifyContent:"flex-start", flexDirection:"column", height:"100%", overflowY:"auto"}}>
                    <div style={{marginTop:"10px", fontSize:"2vmin", display:"flex" , flexDirection:"row"}}><br/><span style={{fontSize:"7px", paddingTop:"14px", paddingRight:"12px"}} className="dotfilter">⚫</span><div><span style={{fontWeight:"bold", fontSize:"2.5vmin"}}> Interior, Dry: According to ANSI A326.3-2021 Standard, hard surface flooring materials installed in this type of area shall have a dry DCOF value of 0.42 or greater</span><br/>
                    Possible Areas in this category, may include but are not
                    limited to: indoor shopping malls (this does not include the
                    food courts); hotel lobbies (except if there are water sources
                    such as fountains); office buildings (this does not include
                    kitchens or restrooms); showrooms; home interiors (this does
                    not include rooms with water sources); and other level areas
                    where hard surface flooring materials will be kept dry and
                    contaminant free when walked upon and proper safety
                    procedures will be followed when cleaning the hard surface
                    flooring materials. Walk-off mats may be necessary for use in
                    entrance areas in this category, due to the possibility that water
                    or other contaminants, which would otherwise not be present,
                    may occasionally and consistently be transported onto the
                    flooring surface. This Area shall be kept dry, level, and free of
                    contaminants when walked upon. Hard surface flooring
                    materials not intended to be walked upon when wet shall have
                    a
                    dry DCOF value at or above 0.42* when using an SBR testfoot (per Section 10.0, ANSI
                    A326.3-2021).</div></div>
                    
                    <div style={{marginTop:"10px", fontSize:"2vmin", display:"flex" , flexDirection:"row"}}><br/><span style={{fontSize:"7px", paddingTop:"14px", paddingRight:"12px"}} className="dotfilter">⚫</span><div><span style={{fontWeight:"bold", fontSize:"2.5vmin"}}> Interior, Wet: According to ANSI A326.3-2021 Standard, hard surface flooring materials installed in this type of area shall have a wet DCOF value of 0.42 or greater</span><br/>
                    Possible Areas in this category, may include but are not
                    limited to: entry foyers, public restrooms (without showers),
                    grocery stores, 'front of the house' in fine and casual dining
                    restaurants with a closed kitchen, home interiors including
                    bathrooms and kitchens, and other areas where hard surface
                    flooring materials may be walked upon when wet if level clean,
                    maintained, and free of standing water or other contaminants.
                    Hard surface flooring materials in this category shall have a wet
                    DCOF value at or above 0.42* when using an SBR testfoot and 0.05% Sodium-Lauryl Sulfate
                     (SLS) solution (per ANSI A326.3-2021 Standard).</div></div>
                    
                   <div style={{marginTop:"10px", fontSize:"2vmin", display:"flex" , flexDirection:"row"}}><br/><span style={{fontSize:"7px", paddingTop:"14px", paddingRight:"12px"}} className="dotfilter">⚫</span><div><span style={{fontWeight:"bold", fontSize:"2.5vmin"}}> Interior, Wet Plus: According to ANSI A326.3-2021 Standard, hard surface flooring materials installed in this type of area shall have a wet DCOF value of 0.50 or greater</span><br/>
                    Possible Areas in this category, may include but are not
                    limited to: public showers, interior pool decks, locker rooms,
                    covered exterior areas, steam rooms, 'front of house' in
                    restaurants with an open kitchen, and 'front of house' in quick
                    service, fast-casual, and self-service restaurants, food areas in
                    gas stations, and other similar areas where the hard surface
                    flooring materials may be walked upon when wet if clean
                    maintained, and free of standing water or other contaminants. Hard surface flooring materials in this category may require frequent maintenance to keep clean, and free of standing water or other contaminants.</div></div>
                    
                   <div style={{marginTop:"10px", fontSize:"2vmin", display:"flex" , flexDirection:"row"}}><br/><span style={{fontSize:"7px", paddingTop:"14px", paddingRight:"12px"}} className="dotfilter">⚫</span><div><span style={{fontWeight:"bold", fontSize:"2.5vmin"}}> Exterior, Wet: According to ANSI A326.3-2021 Standard, hard surface flooring materials installed in this type of area shall have a wet DCOF of 0.55 or greater</span><br/>
                    Possible Areas in this category, may include but are
                    not limited to: level outdoor living spaces including pool
                    decks, walkways, patios, and sidewalks, where such
                    floors may be waiked upon when wet (excluding ice or
                    snow) if level, clean, maintained, and free of standing
                    water or other contaminants. Hard surface flooring
                    materials in this category may require frequent
                    maintenance to keep clean.</div></div>
                    
                   <div style={{marginTop:"10px", fontSize:"2vmin", display:"flex" , flexDirection:"row"}}><br/><span style={{fontSize:"7px", paddingTop:"14px", paddingRight:"12px"}} className="dotfilter">⚫</span><div><span style={{fontWeight:"bold", fontSize:"2.5vmin"}}> Oils/Greases: According to ANSI A326.3-2021 Standard, hard surface flooring materials installed in this type of area shall have a wet DCOF of 0.55 or greater </span><br/>
                    Possible Areas in this category, may include but are not
                    llmited to: level areas regularly exposed to automotive fluids,
                    'back of the house' fast food or family styie restaurants, food
                    preparation areas with grills or deep-fry equipment, and any
                    area where oil, grease and/or fats may be present so long as
                    such floors are level, regularly cleaned, maintained, and free of
                    standing water and contaminant build-up, Hard surface flooring materials in this category may require frequent maintenance to keep clean.</div></div>
                </div>) )}
                width={15} style={{cursor:"pointer",
                 color:"#5C92BF", margin:"0 10px"}} src={infoI}/>
                 <UncontrolledTooltip
                  placement="top"
                  target="UncontrolledTooltipExample_5"
                >
                  Need Help! 
                </UncontrolledTooltip>
                </div>
              <Dropdown
                  options={pendulum ? [
                    { value: "External Pavements and Ramps", label: <div style={{display:"flex"}}>External Pavements and Ramps</div> },
                    { value: "Hospitals and Aged Care Facilities", label: <div style={{display:"flex"}}>Hospitals and Aged Care Facilities</div> },
                    { value: "Hotels, Offices, Public Buildings, Schools; Entries and Access Areas including common areas, internal elevators and Lobbies", label: <div style={{display:"flex"}}>Hotels, Offices, Public Buildings, Schools; Entries and Access Areas including common areas, internal elevators and Lobbies</div> },
                    { value: "Kitchens (Commercial) Serving Areas, Cold Stores", label: <div style={{display:"flex"}}>Kitchens (Commercial) Serving Areas, Cold Stores</div> },
                    { value: "Loading Docks", label: <div style={{display:"flex"}}>Loading Docks</div> },
                    { value: "Sports Stadiums and Arenas", label: <div style={{display:"flex"}}>Sports Stadiums and Arenas</div> },
                    { value: "Supermarkets and Shopping Centers", label: <div style={{display:"flex"}}>Supermarkets and Shopping Centers</div> },
                    { value: "Swimming Pools and Sporting Facilities", label: <div style={{display:"flex"}}>Swimming Pools and Sporting Facilities</div> },
                    { value: "Stairs", label: <div style={{display:"flex"}}>Stairs</div> },
                    { value: "Area Size", label: <div style={{display:"flex", color:"#6DB240"}}>Write Area Size...</div> },
                  ] : [
                    { value :"Interior, Dry: According to ANSI A326.3-2021 Standard, hard surface flooring materials installed in this type of area shall have a dry DCOF value of 0.42 or greater", label:<div style={{display:"flex"}}>Interior, Dry: According to ANSI A326.3-2021 Standard, hard surface flooring materials installed in this type of area shall have a dry DCOF value of 0.42 or greater</div>},
                    { value :"Interior, Wet: According to ANSI A326.3-2021 Standard, hard surface flooring materials installed in this type of area shall have a wet DCOF value of 0.42 or greater", label:<div style={{display:"flex"}}>Interior, Wet: According to ANSI A326.3-2021 Standard, hard surface flooring materials installed in this type of area shall have a wet DCOF value of 0.42 or greater</div>},
                    { value :"Interior, Wet Plus: According to ANSI A326.3-2021 Standard, hard surface flooring materials installed in this type of area shall have a wet DCOF value of 0.50 or greater", label:<div style={{display:"flex"}}>Interior, Wet Plus: According to ANSI A326.3-2021 Standard, hard surface flooring materials installed in this type of area shall have a wet DCOF value of 0.50 or greater</div>},
                    { value :"Exterior, Wet: According to ANSI A326.3-2021 Standard, hard surface flooring materials installed in this type of area shall have a wet DCOF of 0.55 or greater", label:<div style={{display:"flex"}}>Exterior, Wet: According to ANSI A326.3-2021 Standard, hard surface flooring materials installed in this type of area shall have a wet DCOF of 0.55 or greater</div>},
                    { value :"Oils/Greases: According to ANSI A326.3-2021 Standard, hard surface flooring materials installed in this type of area shall have a wet DCOF of 0.55 or greater ", label:<div style={{display:"flex"}}>Oils/Greases: According to ANSI A326.3-2021 Standard, hard surface flooring materials installed in this type of area shall have a wet DCOF of 0.55 or greater </div>},
                    ]}
                  disabled={haveData}
                  onChange={(choice) => { 
                    if(choice.value == "Area Size") {
                      setOtherAreaSize(true)
                    } else {
                      setOtherAreaSize(false)
                    }
                    setTestingCategory(choice.value)
                   }}
                  controlClassName="form-dropdown"
                  placeholder={pendulum ? "Area Type" : "Product Use Category"}
                  value={testingCategory}
                />
                {haveData ? <UncontrolledTooltip
                  placement="top"
                  target="UncontrolledTooltipExample_50290930"
                >
                  Product Use Category can only be changed if you reset the data.
                  To reset the data, go to "Run Test" and edit the Product Use Category.
                </UncontrolledTooltip> : null}
            </div>
            </div>
            <div className="form-row">
            {testingCategory == "Area Size" ? <div className='form-collection' style={{width:"80%"}}>

{otheAreaSize && <input
    onChange={(e) => setAreaSize(e.target.value)}
    value={areaSize}
    type="text"
    name="testingCategotyAreaSize"
    id='testingCategoryAreaSize'
    placeholder="Custome Area Size"
    className="form-input"
  />}

</div> : null}
</div>
            {/* <div className="form-row">
            <div className="form-collection" style={{width:"80%"}}>
              <div className="form-label">
                <span>Testing Condition</span>
            </div>
              <Dropdown
                  options={[
                    { value :"Field/wet Prevailing", label:<div style={{display:"flex"}}>Field/wet Prevailing</div>},
                    { value :"Field/wet Clean", label:<div style={{display:"flex"}}>Field/wet Clean</div>},
                    { value :"Field/dry Prevailing", label:<div style={{display:"flex"}}>Field/dry Prevailing</div>},
                    { value :"Field/dry Clean", label:<div style={{display:"flex"}}>Field/dry Clean</div>},
               ]}
                  onChange={(choice) => {
                    setTestingCondition(choice.value)
                   }}
                  controlClassName="form-dropdown"
                  placeholder="Testing Condition"
                  value={testingCondition}
                />

            </div>
            </div> */}
            <div className="form-row">
            <div className="form-collection" style={{width:"80%"}}>
              <div className="form-label" style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}><span>floor type<span style={{color:"red"}}> *</span></span> {!floorTypeDescriptionActive && <span style={{color: !floorTypeDescriptionActive ? "#6DB240" : "#EF1111" , cursor:"pointer"}} onClick={() => setFloorTypeDescriptionActive(!floorTypeDescriptionActive)}> {!floorTypeDescriptionActive ? "Add" : "Remove"} description</span>}
</div>
              <Dropdown
                  options={floorTypeOptions}
                  onChange={(choice) => {
                    if(choice.value == "Other") {
                        setOtherFloorType(true)
                    } else {
                        setOtherFloorType(false)
                    }
                    setFloorType(choice.value)
                   }}
                  controlClassName="form-dropdown"
                  placeholder="Floor Type"
                  value={floorType ? floorTypeOptions.includes(floorType) ? floorType : "Other" : ""}
                />
            </div>
            </div>
            <div className='form-row'>
            <div className='form-collection' style={{width:"80%"}}>
            {otherFloorType && <input
                onChange={(e) =>  e.target.value ? setFloorTypeTyping(e.target.value) : setFloorTypeTyping("Other")}
                onBlur={() => setFloorType(floorTypeTyping)}
                value={floorTypeTyping == "Other" ? "" : floorTypeTyping}
                type="text"
                name="otherFloorType"
                id='otherFloorType'
                placeholder="Floor Type"
                className="form-input"
              />}
            </div>
            </div>
            { floorTypeDescriptionActive && <div className="form-row">
            <div className="form-collection textarea" style={{width:"80%"}}>
            <div className="form-label"><div>floor type description</div> {<span style={{color: !floorTypeDescriptionActive ? "#6DB240" : "#EF1111" , cursor:"pointer"}} onClick={() => setFloorTypeDescriptionActive(!floorTypeDescriptionActive)}> {!floorTypeDescriptionActive ? "Add" : "Remove"}</span>}</div>
              <textarea
                onBlur={(e) => setFloorTypeDescription(e.target.value)}
                onChange={(e) => setFloorTypeDescriptionTyping(e.target.value)}
                value={floorTypeDescriptionTyping}
                type="text"
                placeholder="Floor Type Description"
                className="form-input textarea-input"
              />
          </div>
          </div> }
          <div className="form-row">
            <div className="form-collection" style={{width:"80%"}}>
              <div className="form-label"><span>floor finish<span style={{color:"red"}}> *</span></span> {!finishConditionDescriptionActive && <span style={{color: !finishConditionDescriptionActive ? "#6DB240" : "#EF1111", cursor:"pointer"}} onClick={() => setFinishConditionDescriptionActive(!finishConditionDescriptionActive)}> {!finishConditionDescriptionActive ? "Add" : "Remove"} description</span>}</div>
              <Dropdown
                  options={floorFinishOptions}
                  onChange={(choice) => {
                    if(choice.value == "Other") {
                        setOtherFloorFinish(true)
                    } else {
                      if(choice.value == "None") {
                        setFinishCondition("N/A")
                      }
                        setOtherFloorFinish(false)
                    }
                    setFloorFinish(choice.value)
                   }}
                  controlClassName="form-dropdown"
                  placeholder="Floor Finish"
                  value={floorFinish ? floorFinishOptions.includes(floorFinish) ? floorFinish : "Other" : ""}
                />

            </div>
            </div>
            <div className='form-row'>
            <div className='form-collection' style={{width:"80%"}}>
            {otherFloorFinish && <input
                onChange={(e) =>  e.target.value ? setFloorFinishTyping(e.target.value) : setFloorFinishTyping("Other")}
                onBlur={() => setFloorFinish(floorFinishTyping)}
                value={floorFinishTyping == "Other" ? "" : floorFinishTyping}
                type="text"
                name="otherFloorFinish"
                id='otherFloorFinish'
                placeholder="Floor Finish"
                className="form-input"
              />}
            </div>
            </div>
            {finishConditionDescriptionActive && <div className="form-row">
            <div className="form-collection textarea" style={{width:"80%"}}>
            <div className="form-label"><div>floor finish description</div> <span style={{color: !finishConditionDescriptionActive ? "#6DB240" : "#EF1111", cursor:"pointer"}} onClick={() => setFinishConditionDescriptionActive(!finishConditionDescriptionActive)}> {!finishConditionDescriptionActive ? "Add" : "Remove"}</span></div>
              <textarea
                 onBlur={(e) => setFloorFinishDescription(e.target.value)}
                 onChange={(e) => setFloorFinishDescriptionTyping(e.target.value)}
                 value={floorFinishDescriptionTyping}
                type="text"
                placeholder="Floor Finish Description"
                className="form-input textarea-input"
              />
          </div>
          </div>}
            <div className="form-row">
            <div className="form-collection" style={{width:"80%"}}>
            <div className="form-label"><span>Floor Texture<span style={{color:"red"}}> *</span></span> <img id="UncontrolledTooltipExample_6" onClick={() => dispatch(ACTION_INFO_ACTIVE(
              <div>
                <div>
                <div style={{marginTop:"10px", fontSize:"2vmin", display:"flex" , flexDirection:"row", margin:"20px"}}><br/><span style={{fontSize:"6px", paddingTop:"12px", paddingRight:"12px"}} className="dotfilter">⚫</span><span style={{fontWeight:"bold"}}>Smooth:&nbsp;</span> Surface texture that has no roughness, bumps, ridges, or holes.<br/></div>
                  <div style={{marginTop:"10px", fontSize:"2vmin", display:"flex" , flexDirection:"row", margin:"20px"}}><br/><span style={{fontSize:"6px", paddingTop:"12px", paddingRight:"12px"}} className="dotfilter">⚫</span><span style={{fontWeight:"bold"}}>Coarse: &nbsp;</span> Surface texture composed of particles, which are non-abrasive and grainy in texture<br/></div>
                  <div style={{marginTop:"10px", fontSize:"2vmin", display:"flex" , flexDirection:"row", margin:"20px"}}><br/><span style={{fontSize:"6px", paddingTop:"12px", paddingRight:"12px"}} className="dotfilter">⚫</span><span style={{fontWeight:"bold"}}>Rough:&nbsp;</span> Surface texture with the character of peak and Valleys; an uneven texture<br/></div>
                </div>
              </div>
            ))} width={15} style={{cursor:"pointer",
                 color:"#5C92BF", margin:"0 10px"}} src={infoI} /><UncontrolledTooltip
    placement="top"
    target="UncontrolledTooltipExample_6"
  >
    Need Help!
  </UncontrolledTooltip> </div>
                            <Dropdown
                  options={["Smooth", "Coarse", "Rough"]}
                  onChange={(choice) => {
                   
                    setTextureType(choice.value)
                   }}
                  controlClassName="form-dropdown"
                  placeholder="Floor Texture"
                  value={TextureType}
                  menuClassName='underMenu'
                />
 
            </div>
            </div>

            <div className="form-row">
            <div className="form-collection" style={{width:"80%"}}>
            <div className="form-label" style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}><span>Floor Finish Condition<span style={{color:"red"}}> *</span></span>
</div>
                            {floorFinish == "None" ? <Dropdown
                              options={["Good", "Fair", "Poor", "N/A"]}
                              onChange={(choice) => {
                              
                                setFinishCondition(choice.value)
                              }}
                              controlClassName="form-dropdown"
                              placeholder="Floor Finish Condition"
                              value={finishCondition}
                              disabled={true}
                              menuClassName='underMenu'
                            /> : <Dropdown
                            options={["Good", "Fair", "Poor", "N/A"]} 
                            onChange={(choice) => {
                            
                              setFinishCondition(choice.value)
                            }}
                            controlClassName="form-dropdown"
                            placeholder="Floor Finish Condition"
                            value={finishCondition}
                            menuClassName='underMenu'
                          /> }

            </div>
            </div>
          <div className="form-row">
            <div className="form-collection" style={{width:"80%"}}>
              <div className="form-label"><span>slope surface<span style={{color:"red"}}> *</span></span></div>
              <Dropdown
                  options={["Level", "Not Level (Ramp)"]}
                  onChange={(choice) => {
                   
                    setslopeSurface(choice.value)
                   }}
                  controlClassName="form-dropdown"
                  placeholder="Slope Surface"
                  menuClassName='underMenu'
                  value={slopeSurface}
                />
            </div>
            </div>

            {pendulum ? <div className="form-row">
            <div className="form-collection" style={{width:"80%"}}>
              <div className="form-label"><span>Identify Sliders Used for the Test.<span style={{color:"red"}}> *</span></span></div>

              <Multiselect
                            selectionLimit={2}
                            options={[{label:"IRHD No. 96 Rubber slider is used for areas where shoe wearing is common."}, {label:"IRHD No. 55 Rubber slider is used for predominately barefoot areas."}]} // Options to display in the dropdown
                            selectedValues={IRHD} // Preselected value to persist in dropdown
                            onSelect={onSelectFunc} // Function will trigger on select event
                            onRemove={onRemoveFunc} // Function will trigger on remove event
                            displayValue="label" // Property name to display in the dropdown options
                    />

              {/* <Dropdown
                  options={["IRHD No. 96 Rubber slider is used for areas where shoe wearing is common.", "IRHD No. 55 Rubber slider is used for predominately barefoot areas."]}
                  onChange={(choice) => {
                   
                    setIRHD(choice.value)
                   }}
                  controlClassName="form-dropdown"
                  placeholder="IRHD No,"
                  menuClassName='underMenu'
                  value={IRHD}
                /> */}
            </div>
            </div> : null}

            </>
            ) : areaModalPage == 3 ? (
              <>
              
              </>

            ) : areaModalPage == 4 ? (
 
              <>
              <div className="form-row" style={{flexDirection:"column"}}>
            <div className="form-collection" style={{width:"100%", fontWeight:"bold"}}>
              Drag dot to add location(s).
            </div>
            <div className="form-collection" style={{width:"100%"}}>
            - Click to move and double click to delete.
            </div> 
            <div className="form-collection" style={{width:"100%"}}>
            - Reminder: One (1) location equals three (3) test samples.
            </div>
            </div> 
              <Planner loadingCanvas={loadingCanvas} setLoadingShape={setLoadingShape} addLocationToDeleted={addLocationToDeleted} fromArea={true} photo={photo} loadMode={addAreaModal.includes("Load") ? true : true} setNewDiagramImage={setNewDiagramImagePending} areaLines={areaLines.lines} areaShapes={areaLines.shapes} locationMode={true} />
            </>

            ) : areaModalPage == 5 ? (
          <>
              <div className="form-row">
                <div className="form-collection textarea" style={{width:"80%"}}>
                <div className="form-label">Auditor's Comments</div>
                <div className='label-statement'>
                Auditor can add any comments or notes that they would like included in the Audit Report. These comments should pertain to environmental conditions such as contaminant type present, traffic conditions, weather conditions (if exterior testing), maintenance chemicals, maintenance equipment, the presence of walk-off mats, or any exclusions (such as blocked off hallways or sections under construction).
                </div>
                  <textarea
                    onChange={(e) => setAreaNotesTyping(e.target.value)}
                    onBlur={(e) => setAreaNotes(areaNotesTyping)}
                    value={areaNotesTyping}
                    type="text"
                    placeholder="Auditor's Comments"
                    className="form-input textarea-input"
                  />
              </div>
              </div>
            </>

            ) : null
          }
            </> : (
                <DataSpinner small="l" /> 
            ) }
          </div>
          <div style={{position:"fixed", width:"100%", height:"33px", alignItems:"flex-end", padding:"10px", bottom:0, left:0, right:0, display:"flex" , justifyContent: "space-between", width:"100%", marginTop:".8rem"}}>
          { areaModalPage > 0 ? <div
            style={{margin:".5rem"}}
            className="auth-button addUser"
            onClick={() => {              
              if(areaModalPage == 4) {              
                setAreaModalPage(areaModalPage - 2)
              } else if(areaModalPage == 2) {
                addAreaModal.includes("Load") ? setAreaModalPage(areaModalPage - 2) : setAreaModalPage(areaModalPage - 1)
              } else {
                if(areaModalPage == 1) {
                  changeWebcamURI("");
                changeImagePreviewAvailable(
                  false 
                );
                changeImagePreviewAvailable(
                  false
                );
                changeTakeAPhotoSelected(false);
                changeImageUploaded("");
                setAreaModalPage(areaModalPage - 1)
                } else {
                setAreaModalPage(areaModalPage - 1)
                }

              }
            }}
          >
            Back
          </div> : addAreaModal.includes("Load") ? <div
            style={{margin:".5rem"}}
            className={areaModalPage == 0 ?
              areaName && !areaNameError && !areaNumberError && ((images?.length > 0 && !images.some(x => x.url == "replace") || imagesLater)) ?
                "auth-button addUser" :
              "auth-button addUser disable-button"
              :"auth-button addUser disable-button"}
            onClick={
              areaModalPage == 0 ?
              areaName  && !areaNameError && !areaNumberError && ((images?.length > 0 && !images.some(x => x.url == "replace") || imagesLater)) ?
              () => {
                if(addAreaModal.includes("Load")) {
                  if(linesPending?.length || photo) {
                  setLoadingCanvas(true)
                  html2canvas(document.getElementById("stageImage"), {useCORS:true,}).then(canvas => {
                  let dataURL = canvas.toDataURL()
                  setDiagramImage && setDiagramImage(dataURL)
                  setLines(linesPending)
                  setDiagramImage(dataURL)
                  uploadFile(dataURL, "load")
                  setLoadingCanvas(false)            
                })
              }}
              }
              : null
              : null

            }
          >
            Load</div> : <div></div> }

            <div
            
              className={
                !LoadingShape ? progress && progress !== 100 ? "auth-button addUser disable-button": areaModalPage == -1 ? "auth-button addUser" : areaModalPage == 0 ?
                    areaName && !areaNameError && !areaNumberError ? !pendulum ? ((images?.length > 0 && !images.some(x => x.url == "replace")) || (imagesLater)) ? "auth-button addUser" :
                    "auth-button addUser disable-button" : pendulum ? images?.length > 1 && !images.some(x => x.url == "replace") ?
                    "auth-button addUser" :
                    "auth-button addUser disable-button" :
                    "auth-button addUser disable-button" :
                    "auth-button addUser disable-button"
                    :
                    areaModalPage == 1 ?
                    linesPending?.length || photo ?
                      "auth-button addUser" :
                    "auth-button addUser disable-button"
                    :
                    areaModalPage == 2 ?
                    testingCategory && floorType && floorFinish && TextureType && slopeSurface ?
                      "auth-button addUser" :
                    "auth-button addUser disable-button"
                    :
                    areaModalPage == 3 ?
                    floorFinish && finishCondition ?
                      "auth-button addUser" :
                    "auth-button addUser disable-button"
                    :
                    areaModalPage == 4 ?
                    true ?
                      "auth-button addUser" :
                    "auth-button addUser disable-button"
                    :
                    areaModalPage == 5 ?
                    addAreaModal.includes("Edit") ?
                    "auth-button addUser" :
                    images?.length > 0 || imagesLater ?
                    "auth-button addUser" :
                    "auth-button addUser disable-button"
                    : "disable-button" : "auth-button addUser disable-button"

              }
              style={{margin:".5rem"}}
              onClick={
                !LoadingShape ? progress && progress !== 100 ? null : areaModalPage == -1 ? () => setAreaModalPage(0) : areaModalPage == 0 ?
                areaName  && !areaNameError && !areaNumberError && ((images?.length > 0 && !pendulum && !images.some(x => x.url == "replace") || imagesLater || (pendulum && images?.length > 1 && !images.some(x => x.url == "replace"))  )) ?
                () => {
                  if(addAreaModal.includes("Load")){
                   if(linesPending?.length || photo) {
                    setLoadingCanvas(true)
                    html2canvas(document.getElementById("stageImage"),{useCORS:true,}).then(canvas => {
                    let dataURL = canvas.toDataURL()
                    setDiagramImage && setDiagramImage(dataURL)
                    setLines(linesPending)
                    setDiagramImage(dataURL)
                    uploadFile(dataURL)
                    setLoadingCanvas(false)            
                  })
                setAreaModalPage(areaModalPage + 2)
                  }} else { setAreaModalPage(areaModalPage + 1)}} : null
                :
                    areaModalPage == 1 ?
                    linesPending?.length || photo ? () => {
                    setLoadingCanvas(true)
                    html2canvas(document.getElementById("stageImage"), {useCORS:true,}).then(canvas => {
                    let dataURL = canvas.toDataURL()
                    setDiagramImage && setDiagramImage(dataURL)
                    setLines(linesPending)
                    setDiagramImage(dataURL)
                    uploadFile(dataURL)
                    setAreaModalPage(areaModalPage + 1)
                    setLoadingCanvas(false)            
                  })
                  
                } : null
                :
                areaModalPage == 2 ?
                testingCategory &&floorType && floorFinish && finishCondition && slopeSurface?
                () => {
                  let diagram = useShapes.get()
                  let finalDiagram = {}
                  finalDiagram.shapes = diagram.shapes
                  finalDiagram.lines = diagram.lines
                  setAreaLines(finalDiagram) 
                  setAreaModalPage(areaModalPage + 2)
                } : null
                :
                areaModalPage == 3 ?
                floorFinish && finishCondition ?
                () => setAreaModalPage(areaModalPage + 1) : null
                :
                areaModalPage == 4 ?
                slopeSurface ?
                () => {
                  setLoadingCanvas(true)
                  setTimeout(() => {
                    html2canvas(document.getElementById("stageImage"), {useCORS:true,}).then(canvas => {
                      let dataURL = canvas.toDataURL()
                      setNewDiagramImage && setNewDiagramImage(dataURL)
                      setAreaModalPage(areaModalPage + 1)
                      setLoadingCanvas(false)
                    })
                  }, 1)
                } : null
                :
                areaModalPage == 5 ?
                addAreaModal.includes("Load") ? () => addAreaFunc("load") : addAreaModal.includes("Edit") ? () => updateAreaFunc(selectedArea) : images?.length > 0 || imagesLater ? () => addAreaFunc() : null
                : null : null  
              }
            >
              { !LoadingShape ? progress && progress !== 100 ? <DataSpinner small="s" /> : areaModalPage == 0 && addAreaModal.includes("Load") ? "Continue editing!" : areaModalPage == 5 ? addAreaModal.includes("Load") ? "Load": addAreaModal.includes("Edit") ? "Update" : "Done" : "Next" : <DataSpinner small="s" />}
            </div>

          </div>
        </div>) : (
           <div className="modal task-modal" style={{height:"fit-content", width:"44vw"}}>          
            <FontAwesomeIcon
          className="modal_x"
          icon={faTimes}
          onClick={() => {      
            setImages([]) 
            setHaveData && setHaveData(false) 
            setAddAreaDropDown(true)         
            setAddAreaModal(null)           
          }}
        />  
    <h1 className="header" style={{width:"100%"}}><span style={{opacity:".9", cursor:"pointer"}}></span> <span>Add Photos Modal</span> </h1>        
    <div style={{alignItems:"center"}} className="form-group-container task-form">
          <div className="form-row">
          <div className="form-collection textarea" style={{width:"80%"}}>
            <AvatarModal
            pendulum={pendulum}
            progress={progress}
            setProgress={setProgress}
            images={images}
            setImages={setImages}
            takeAPhotoSelected={takeAPhotoSelected}
            changeTakeAPhotoSelected={changeTakeAPhotoSelected}
            webcamURI={webcamURI}
            changeWebcamURI={changeWebcamURI}
            imageUploaded={imageUploaded}
            changeImageUploaded={changeImageUploaded}
            imagePreviewAvailable={imagePreviewAvailable}
            changeImagePreviewAvailable={changeImagePreviewAvailable}
            handleDeletedPreviewImage={handleDeletedPreviewImage}
            handleImageUploaded={handleImageUploaded}
            addProfilePhotoClicked={addProfilePhotoClicked}
            setAddProfileClicked={setAddProfileClicked} 
            />
      </div>
      </div>
      </div>
      <div style={{width:"100%", display:"flex", justifyContent:"flex-end"}}>
      <div
            
              className={
                 progress && progress !== 100 ? "auth-button addUser disable-button":images?.length > 0 ?
                    "auth-button addUser" :
                    "auth-button addUser disable-button"
                    

              }
              style={{minHeight:"30px"}}
              onClick={ () => {
                progress && progress !== 100 ? null :images?.length > 0 ? updateAreaImages(selectedArea) : null  
                

              }}
            >
              {progress && progress !== 100 ? <DataSpinner small="s" /> :"Save"}
            </div>
            </div>
      </div>
        )}
      </Modal>
  )}

  export default AddAreaModal
       