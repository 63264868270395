// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accountContainer {
  width: 100%;
}

.tableContainer {
  background-color: white;
  border-radius: 8px;
  padding: 0.7rem;
  width: 100%;
}

.reportsRadius {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.tableSitesHeader {
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/resuable/accountTable/accountTable.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;;AAEA;EACI,uBAAA;EACA,kBAAA;EACA,eAAA;EACA,WAAA;AACJ;;AACA;EACI,oCAAA;EACA,qCAAA;AAEJ;;AACA;EACI,iBAAA;EACJ,eAAA;EAEA,qBAAA;EACA,mBAAA;AACA","sourcesContent":[".accountContainer {\n    width: 100%;\n    \n}\n.tableContainer {\n    background-color: white;\n    border-radius: 8px;\n    padding: .7rem;\n    width: 100%;\n}\n.reportsRadius {\n    border-top-left-radius: 0 !important;\n    border-top-right-radius: 0 !important;\n}\n\n.tableSitesHeader {\n    font-weight: bold;\nfont-size: 24px;\n// line-height: 28px;\nletter-spacing: 0.5px;\nmargin-bottom: 1rem ;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
