// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contactUs {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  padding: 20px 50px;
  background: #38373C;
}

.contactUsContent {
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  display: flex;
  color: #f0efef;
  justify-content: center;
}

.contactUsLeft,
.contactUsRight {
  min-width: 400px;
  width: 45%;
  height: 100%;
  padding: 30px 50px;
}

.contactUsRight {
  position: relative;
}

.contactUsLeft {
  display: flex;
  flex-direction: column;
}

.leftHeader {
  color: white;
  font-size: 7vmin;
  max-width: 70%;
  margin-bottom: 2.5rem;
}

.leftIcons {
  display: flex;
}

.innerIcon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  color: white;
}

.whiteInput {
  background-color: white !important;
  height: 40px;
}

.grayLabel {
  color: #f0efef;
  font-weight: lighter;
}`, "",{"version":3,"sources":["webpack://./src/components/contactUs/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,aAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;AACJ;;AACA;EACI,WAAA;EACA,YAAA;EACA,eAAA;EACA,aAAA;EACA,cAAA;EACA,uBAAA;AAEJ;;AAAA;;EAEI,gBAAA;EACA,UAAA;EACA,YAAA;EACA,kBAAA;AAGJ;;AADA;EACI,kBAAA;AAIJ;;AAFA;EACI,aAAA;EACA,sBAAA;AAKJ;;AAHA;EACI,YAAA;EACA,gBAAA;EACA,cAAA;EACA,qBAAA;AAMJ;;AAJA;EACI,aAAA;AAOJ;;AALA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;AAQJ;;AANA;EACI,kCAAA;EACA,YAAA;AASJ;;AAPA;EACI,cAAA;EACA,oBAAA;AAUJ","sourcesContent":[".contactUs {\n    display: flex;\n    flex-direction: column;\n    height:100vh;\n    width:100vw;\n    overflow-y: auto;\n    padding: 20px 50px;\n    background: #38373C;\n}\n.contactUsContent {\n    width: 100%;\n    height: 100%;\n    flex-wrap: wrap;\n    display: flex;\n    color: #f0efef;\n    justify-content: center;\n}\n.contactUsLeft,\n.contactUsRight {\n    min-width: 400px;\n    width: 45%;\n    height: 100%;\n    padding: 30px 50px;\n}\n.contactUsRight {\n    position: relative;\n}\n.contactUsLeft {\n    display: flex;\n    flex-direction: column;\n}\n.leftHeader {\n    color: white;\n    font-size: 7vmin;\n    max-width: 70%;\n    margin-bottom: 2.5rem;\n}\n.leftIcons {\n    display: flex;\n}\n.innerIcon {\n    width: 20px;\n    height: 20px;\n    margin-right: 10px;\n    color: white;\n}\n.whiteInput {\n    background-color: white !important;\n    height: 40px;\n}\n.grayLabel {\n    color: #f0efef;\n    font-weight: lighter;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
