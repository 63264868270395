import React, { useState } from 'react'
import Modal from "react-modal"
import "../../../../assests/styles/Modal.scss"

const PendulumCalibrationModal = (props) => {
    const {
        active,
        setActive,
        pendulumSerial,
        setPendulumSerial,
        pendulumCalibrations,
        setPendulumCalibrations,
        pendulumManufacturer,
        setPendulumManufacturer,
        pendulumPerformedBy,
        setPendulumPerformedBy,
        pendulumDueDate,
        setPendulumDueDate,
        updatePendulumFunc,       
    } = props

    const serialTyping = (e) => {
      if (
        (e.keyCode >= 8 && e.keyCode < 32) ||
        (e.keyCode >= 37 && e.keyCode <= 40) ||
        (e.keyCode >= 65 && e.keyCode <= 105) ||
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        (e.keyCode == 17) || 
        (e.keyCode == 86)

      ) {
        return e.keyCode;
      } else {
       e && e.preventDefault();
      }
    };

    const serialValueHandle = (e) => {
      let currentTyping = e.currentTarget.value;
      
          setPendulumSerial(currentTyping)
      
    };


    const calibrationHandle = (e) => {
      let currentTyping = e.currentTarget.value;
      
          setPendulumCalibrations(currentTyping)
      
    };
    const manufacturerHandle = (e) => {
      let currentTyping = e.currentTarget.value;
      
          setPendulumManufacturer(currentTyping)
      
    };
    const performedByHandle = (e) => {
      let currentTyping = e.currentTarget.value;
      
          setPendulumPerformedBy(currentTyping)
      
    };
    const dueDateHandle = (e) => {
      let currentTyping = e.currentTarget.value;
      
          setPendulumDueDate(currentTyping)
      
    };

  return(
<Modal
        isOpen={active ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        { active && <div className="modal task-modal" style={{height: "fit-content", width:"50vw", justifyContent:"space-between"}}>
               

          <h1 className="header" style={{width:"100%", position:"fixed", top:0, left:0, right:0, width:"100%", height:"33px"}}>Pendulum Calibration</h1>
          <div className="form-group-container task-form"style={{height:"100%", margin:"50px 0", justifyContent:"flex-start", overflowY:"auto"}}> 


          <div className="form-row" >

<div className="form-collection textarea" style={{width:"100%"}}>
  <div className="form-label"><span>Pendulum Manufacturer<span style={{color:"red"}}> *</span></span></div>
  <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
  {/* <div style={{width:"2%"}}>#</div> */}
  <div style={{width:"100%"}}>
  <input
          placeholder='Manufacturer name'
            onBlur={(e) => e.target.value && setPendulumManufacturer(e.target.value)}
            // onKeyDown={serialTyping}
            onChange={manufacturerHandle}
            value={pendulumManufacturer}
            type="text"
            className="form-input"
            style={{marginBottom:"0"}}
          />
  </div>
  </div>
  </div>
</div>

          <div className="form-row">
                <div className="form-collection textarea" style={{width:"100%"}}>
                <div className="form-label"><span>Pendulum Serial<span style={{color:"red"}}> *</span></span></div>
                <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                <div style={{width:"2%"}}>#</div>
                <div style={{width:"98%"}}>
                <input
                        placeholder='XXXXXX'
                          onBlur={(e) => e.target.value && setPendulumSerial(e.target.value)}
                          onKeyDown={serialTyping}
                          onChange={serialValueHandle}
                          value={pendulumSerial}
                          type="text"
                          className="form-input"
                          style={{marginBottom:"0"}}
                        />
                </div>
                </div>
                </div>

              </div>

              <div className="form-row" >

              <div className="form-collection textarea" style={{width:"100%"}}>
                <div className="form-label"><span>Calibration Certificate<span style={{color:"red"}}> *</span></span></div>
                <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                <div style={{width:"2%"}}>#</div>
                <div style={{width:"98%"}}>
                <input
                        placeholder='XXXXXX'
                          onBlur={(e) => e.target.value && setPendulumCalibrations(e.target.value)}
                          onKeyDown={serialTyping}
                          onChange={calibrationHandle}
                          value={pendulumCalibrations}
                          type="text"
                          className="form-input"
                          style={{marginBottom:"0"}}
                        />
                </div>
                </div>
                </div>
</div>

<div className="form-row" >

<div className="form-collection textarea" style={{width:"100%"}}>
  <div className="form-label"><span>Calibration Performed By<span style={{color:"red"}}> *</span></span></div>
  <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
  {/* <div style={{width:"2%"}}>#</div> */}
  <div style={{width:"100%"}}>
  <input
          placeholder='Performed by'
            onBlur={(e) => e.target.value && setPendulumPerformedBy(e.target.value)}
            // onKeyDown={serialTyping}
            onChange={performedByHandle}
            value={pendulumPerformedBy}
            type="text"
            className="form-input"
            style={{marginBottom:"0"}}
          />
  </div>
  </div>
  </div>
</div>


<div className="form-row" >

<div className="form-collection textarea" style={{width:"100%"}}>
  <div className="form-label"><span>Next Calibration Due Date<span style={{color:"red"}}> *</span></span></div>
  <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
  {/* <div style={{width:"2%"}}>#</div> */}
  <div style={{width:"100%"}}>
  <input
          placeholder='Next Calibration Due Date'
            onBlur={(e) => e.target.value && setPendulumDueDate(e.target.value)}
            // onKeyDown={serialTyping}
            onChange={dueDateHandle}
            value={pendulumDueDate}
            type="text"
            className="form-input"
            style={{marginBottom:"0"}}
          />
  </div>
  </div>
  </div>
</div>

          </div>
                    <div style={{position:"fixed", width:"100%", height:"33px", alignItems:"flex-end", padding:"10px", bottom:0, left:0, right:0, display:"flex" , justifyContent: "space-between", width:"100%", marginTop:".8rem"}}>

          <div
                
                className="auth-button addUser confirmDelete deleteButton"
                onClick={() => {                  
                    setSerial("")
                    setWetValue("")
                    setDryValue("")
                    setActive(null)
                }}
            >

            Cancel

            </div>
            <div
            style={{margin:".5rem"}}
              className={`${pendulumSerial ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={pendulumSerial ?  updatePendulumFunc : null}
            >
              
              Update

            </div>
          </div>
        </div>}
      </Modal>
  )}

  export default PendulumCalibrationModal
       