// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-item {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin: 0 10px;
  cursor: pointer;
}

.primaryPhoto {
  border-radius: 4px;
  border: 2px solid #6DB240 !important;
}

.zoomedPhoto {
  border-radius: 4px;
  border: 2px solid rgb(3, 134, 215) !important;
}

.image-item__btn-wrapper {
  background-color: #FBF4F4;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/resuable/modals/addAreaModal/avatarModal/avatarModal.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;AACJ;;AACA;EACI,kBAAA;EACA,oCAAA;AAEJ;;AAAA;EACI,kBAAA;EACA,6CAAA;AAGJ;;AADA;EACI,yBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AAIJ","sourcesContent":[".image-item {\n    border: 1px solid #e0e0e0;\n    border-radius: 4px;\n    margin: 0 10px;\n    cursor: pointer;\n}\n.primaryPhoto {\n    border-radius: 4px;\n    border: 2px solid #6DB240 !important;\n}\n.zoomedPhoto {\n    border-radius: 4px;\n    border: 2px solid rgb(3, 134, 215) !important;\n}\n.image-item__btn-wrapper {\n    background-color: #FBF4F4;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
