// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auditField {
  margin: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 0;
  align-items: flex-start;
}
@media screen and (max-width: 900px) {
  .auditField {
    margin-top: 0.7rem;
  }
}

.detailHeader {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-transform: capitalize;
  /* identical to box height */
  letter-spacing: 0.5px;
  /* grayscale / black */
  color: #505463;
  opacity: 0.6;
}

.detailContent {
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px !important;
  letter-spacing: 0.5px;
  /* grayscale / black */
  color: #505463 !important;
}

.detailsAvatar {
  padding: 0 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/resuable/accountHeader/accountHeader.scss"],"names":[],"mappings":"AACA;EACI,cAAA;EACA,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,eAAA;EACA,uBAAA;AAAJ;AACI;EAPJ;IAQQ,kBAAA;EAEN;AACF;;AACA;EACA,2HAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EAEA,0BAAA;EACA,4BAAA;EAEA,qBAAA;EAEA,sBAAA;EAEA,cAAA;EAEA,YAAA;AAHA;;AAKA;EACI,kBAAA;EACA,2BAAA;EACA,0BAAA;EAEA,qBAAA;EAEA,sBAAA;EAEA,yBAAA;AALJ;;AAOA;EACI,iBAAA;AAJJ","sourcesContent":["\n.auditField {\n    margin: 0 1rem;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    padding: 20px 0;\n    align-items: flex-start;\n    @media screen and (max-width:900px) {\n        margin-top: .7rem;\n        \n    }\n}\n.detailHeader {\nfont-family: \"HelveticaNeue-Light\", \"Helvetica Neue Light\", \"Helvetica Neue\", Helvetica, Arial, \"Lucida Grande\", sans-serif; \nfont-style: normal;\nfont-weight: normal;\nfont-size: 12px;\n// line-height: 14px;\ntext-transform: capitalize;\n/* identical to box height */\n\nletter-spacing: 0.5px;\n\n/* grayscale / black */\n\ncolor: #505463;\n\nopacity: 0.6;\n}\n.detailContent {\n    font-style: normal;\n    font-weight: 700  !important;\n    font-size: 14px !important;\n    // line-height: 16px;\n    letter-spacing: 0.5px;\n    \n    /* grayscale / black */\n    \n    color: #505463 !important;\n}\n.detailsAvatar  {\n    padding: 0 .5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
