// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.templatesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  padding-top: 1rem;
}

.templateContainerParent {
  width: 25%;
  padding: 0 0.5rem;
}
@media screen and (max-width: 900px) {
  .templateContainerParent {
    min-width: 250px;
  }
}

.templatecontainer {
  position: relative;
  border: 1px solid #DFE0EB;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  justify-content: space-between;
  padding: 16px;
}

.templateHeader {
  color: #666;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  text-transform: uppercase;
}

.form-check-input {
  cursor: pointer;
}

.templateThumbnail {
  height: 200px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.templateThumbnail img {
  border: 1px solid #DFE0EB;
  box-sizing: border-box;
  border-radius: 8px;
}

.confirmModal {
  height: 50vh !important;
}`, "",{"version":3,"sources":["webpack://./src/components/resuable/reportTemplate/templates/templates.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,eAAA;EACA,2BAAA;EACA,WAAA;EACA,iBAAA;AACJ;;AACA;EACI,UAAA;EACA,iBAAA;AAEJ;AADI;EAHJ;IAIQ,gBAAA;EAIN;AACF;;AADA;EACI,kBAAA;EACA,yBAAA;EACA,sBAAA;EACA,kBAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,8BAAA;EACA,aAAA;AAIJ;;AAAA;EACI,WAAA;EACA,aAAA;EACA,8BAAA;EACA,gBAAA;EACA,yBAAA;AAGJ;;AADA;EACI,eAAA;AAIJ;;AAFA;EACI,aAAA;EACA,gBAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;AAKJ;AAJI;EACI,yBAAA;EACA,sBAAA;EACA,kBAAA;AAMR;;AAHA;EACI,uBAAA;AAMJ","sourcesContent":[".templatesContainer {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: flex-start;\n    width: 100%;\n    padding-top: 1rem;\n}\n.templateContainerParent {\n    width: 25%;\n    padding: 0 .5rem;\n    @media screen and (max-width: 900px) {\n        min-width: 250px;\n        \n    }\n}\n.templatecontainer {\n    position: relative;\n    border: 1px solid #DFE0EB;\n    box-sizing: border-box;\n    border-radius: 8px;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 1rem;\n    justify-content: space-between;\n    padding: 16px;\n\n}\n\n.templateHeader { \n    color: #666;\n    display:flex;\n    justify-content: space-between;\n    margin-top:1rem;\n    text-transform:uppercase;\n}\n.form-check-input {\n    cursor: pointer;\n}\n.templateThumbnail {\n    height: 200px;\n    overflow: hidden;\n    cursor: pointer;\n    display: flex;\n    justify-content: center;\n    img {\n        border: 1px solid #DFE0EB;\n        box-sizing: border-box;\n        border-radius: 8px;\n        }\n}\n.confirmModal{\n    height: 50vh !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
