// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infoPop {
  display: none;
  position: absolute;
  bottom: -100%;
  z-index: 99999;
  left: -100%;
  width: 230px;
  height: fit-content;
  padding: 10px 12px;
  background-color: rgba(0, 0, 0, 0.796);
  color: rgb(246, 246, 246);
  border-radius: 8px;
  font-size: 14px;
  position: absolute;
  bottom: 0px;
  z-index: 99999999999;
  right: 0vw;
  left: 0vw;
  min-width: 29vw;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  max-height: 410px;
  overflow: auto;
}

.infoContainer:hover .infoPop {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/resuable/info/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,kBAAA;EACA,aAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,sCAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;EACA,oBAAA;EACA,UAAA;EACA,SAAA;EACA,eAAA;EACA,2BAAA;EACA,wBAAA;EACA,iBAAA;EACA,cAAA;AACJ;;AAGK;EACG,cAAA;AAAR","sourcesContent":[".infoPop {\n    display: none;\n    position: absolute;\n    bottom: -100% ;\n    z-index: 99999;\n    left: -100%;\n    width: 230px;\n    height: fit-content;\n    padding: 10px 12px;\n    background-color: rgba(0, 0, 0, 0.796);\n    color: rgb(246, 246, 246);\n    border-radius: 8px;\n    font-size: 14px;\n    position: absolute;\n    bottom: 0px;\n    z-index: 99999999999;\n    right: 0vw;\n    left: 0vw;\n    min-width: 29vw;\n    height: -webkit-fit-content;\n    height: -moz-fit-content;\n    max-height: 410px;\n    overflow: auto;\n}\n.infoContainer {\n    &:hover {\n     .infoPop {\n        display: block;   \n    }\n}\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
