import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import ImageUploading from "react-images-uploading";
import "./avatarModal.scss"
import archive from "../../../../../assests/icons/archive.svg"
import LZString from "lz-string";
import add from "../../../../../assests/icons/plus.png"
import AWS from 'aws-sdk'
import { DataSpinner } from "../../../spinner/dataSpinner";
import { UncontrolledTooltip } from "reactstrap";

const S3_BUCKET ='step-us-east-prod';
const REGION ='us-east-1';



if(process.env.REACT_APP_AWS_DEV) { 
AWS.config.update({
   accessKeyId: process.env.REACT_APP_AWS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS
})
}


 
const myBucket = new AWS.S3({
    params: { Bucket: process.env.REACT_APP_S3_BUCKET},
    region: REGION,
}) 
function MiniAvatarModal({images, setImages, progress, setProgress, _id, i}) {
  const [selectedFile, setSelectedFile] = useState(null);

  const uploadFiles = (files) => {
    let filteredFiles = files.filter(x => !x.url)
    filteredFiles.map(async x => {
      const base64Data = new Buffer.from(x.data_url.replace(/^data:image\/\w+;base64,/, ""), 'base64');
      const type = x.data_url.split(';')[0].split('/')[1];
    const fileName = `${x.file.name.replace(/\s/g, '').replace("#", "").replace("_", "").replace("/", "")}-${global.Date.now()}.png`
        const params = {
          ACL: 'public-read',
          Body: base64Data,
          Key: fileName,
          ContentEncoding: 'base64', // required
          ContentType: `image/${type}` // required. Notice the back ticks
        };
      myBucket.putObject(params)
        .on('httpUploadProgress', (evt) => {
            setProgress(Math.round((evt.loaded / evt.total) * 100))
        })
        .on("complete", (evt) => {
          x.url = `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/${fileName}`
          setImages(_id, files.map(y => x?.file?.name == y?.file?.name ? x : y))
        })
        .send((err) => {
            if (err) console.log(err)  
        })
      })
      
  }
  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    uploadFiles(imageList)
    setImages(_id, imageList)
  };

  const move = (from, to, arr) => {
    const newArr = [...arr];

    const item = newArr.splice(from, 1)[0];
    newArr.splice(to, 0, item);

    return newArr;
}
  const replaceImage = (index) => {
    let newImages = [...images]
    let newImagesArr = move(index, 0, newImages)
    setImages(_id , newImagesArr)
  }

  return (
    <div>
      <ImageUploading
        multiple 
        value={images}
        onChange={onChange} 
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps
        }) => (
          // write your building UI
          <div className="upload__image-wrapper" style={{height:"100%"}}>
            <div
              style={{ cursor:"pointer", color: isDragging ?"#EF1111": null, border:"1px solid #e0e0e0", padding:"7px", borderRadius:"4px", minHeight:"50px", display:"flex", justifyContent:"center", alignItems:"center" }}
              onClick={ () => {
               
                  onImageUpload()
                
              }}
              {...dragProps}
            >
              {images?.length == 0 && <span style={{color:"rgb(182 182 182)", fontWeight:"bold"}}>Click to Upload or Drop Photo of Area here</span>}
           
            {/* <div onClick={(e) => { 
              e.stopPropagation() 
              onImageRemoveAll()}}>Remove all images</div> */}
            {imageList?.length > 0 && <div style={{ width:"100%", maxHeight:"70vh", overflow:"auto", display:"flex", flexWrap:"wrap", justifyContent:"center", position:"relative", overflow:"visible",}}>
                {imageList?.map((image, index) => (
                  <div key={index}>
              <UncontrolledTooltip
                style={{ opacity:1, overflow:"hidden", background:"gray", borderRadius:"8px", border:"1px solid gray", padding:0}}
                placement="top"
                target={`UncontrolledTooltipExample_later_${i}_${index}`}
              >
                <img onClick={(e) =>{
                        e.stopPropagation()
                        onImageUpdate(index)
                      }}
                         src={image.url} alt="" height={"100"} width={"100"} style={{objectFit:"cover"}} />
              </UncontrolledTooltip> 
              <UncontrolledTooltip
                style={{ opacity:1, overflow:"hidden", background:"gray", borderRadius:"8px", border:"1px solid gray", padding:0}}
                placement="bottom"
                target={`UncontrolledTooltipExample_later_${i}_${index}`}
              >
               click to delete
              </UncontrolledTooltip> 
                  <div id={`UncontrolledTooltipExample_later_${i}_${index}`} key={index} className={`image-item ${index == 0 && image.url ? "primaryPhoto" : ""}`}>
                    {index == 0 && <img style={{position:"absolute", width:"50px", color:"#101010", textTransform:"capitalize", height:'fit-content', display:"flex", alignItems:"center", justifyContent:"center", top:-20, left:-50}} src="https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/64818.png-1678714683886.png" />}
                    {index == 0 &&  <div style={{position:"absolute", width:"fit-content", color:"#101010", textTransform:"capitalize", height:'fit-content', display:"flex", alignItems:"center", justifyContent:"center", top:-45, left:-120}}>Main Photo</div>}
                    <div style={{margin:"5px"}}>
                      {image.url ? <> <img 
                        onClick={(e) =>{
                        e.stopPropagation()
                        onImageRemove(index)}}
                         src={image?.url == "replace" ? add : image.url} alt="" height={"20"} width={"20"} style={{objectFit:"cover"}} />
                    {/* <div onClick={(e) =>{
                        e.stopPropagation()
                        onImageRemove(index)}} className="image-item__btn-wrapper"> 
                      <div >
                        <img src={archive} height={20} />
                        </div> 
                    </div> */}
                    </> : <DataSpinner small="sm" />}
                    </div>
                  </div> 
                  </div>
                ))} 
                <>
                <UncontrolledTooltip
                style={{ opacity:1, overflow:"hidden", background:"gray", borderRadius:"8px", border:"1px solid gray", padding:0}}
                placement="top"
                target={`UncontrolledTooltipExample_later_${"add"}`}
              >
                  Additional Photo will be added to "Audit Photos"
              </UncontrolledTooltip> 
                {!images.some(x => x.url == "replace") && <div id={`UncontrolledTooltipExample_later_${"add"}`} style={{ padding:"5px", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}} className="image-item">
                    <img onClick={(e) => {
                      e.stopPropagation()
                      onImageUpload()
                      }} src={add} alt="" height={"20"} width={"20"} style={{objectFit:"cover"}} />
                      {/* <div style={{fontWeight:"bold"}}>Add Photo</div> */}
                </div>}</>
            </div>}
            </div>
          </div>
        )}
      </ImageUploading>
    </div>
  ); 
}
 export default MiniAvatarModal
