import { useMutation, useQuery } from "@apollo/react-hooks"
import Cookies from "js-cookie"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect, useHistory, useLocation } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import "./templates.scss"
import { DataSpinner } from "../../spinner/dataSpinner"
import ACTION_LOADING_SPINNER_RESET from "../../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET"
import ACTION_LOADING_SPINNER_ACTIVE from "../../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE"
import LZString from "lz-string"
import add from "../../../../assests/icons/add.svg"
import {Input, FormGroup, Label} from "reactstrap"

import updateTemplateStatusMutation from "../../../../graphql/mutations/template/updateTemplateStatusMutation"
import ConfirmDefaultModal from "../../modals/addTemplateModal/confirmDefaultModal"

const ReportTemplates = (props) => {

    const {
        reportTemplates,
        templatesRefetch,
        user
    } = props
    const returnToHomeScreen = () => {
        if(!Cookies.get("dummy-token")) {
            return <Redirect to="/admin" />
        }
        if(user?.user.role !== "admin") { 
            return <Redirect to="/admin/dashboard" />   
        }
    }
    const dispatch = useDispatch()
    const location = useLocation()
    const history = useHistory()
    const [templates, setTemplates] = useState(null)
    const [templateTypes, setTemplateTypes] = useState([
        {
            type: "dcof",
            expanded: true,
        },
        {
            type: "scof",
            expanded: false
        },
        {
            type: "single",
            expanded: false
        },
        {
            type: "pendulum",
            expanded: false
        }
    ])

    useEffect(() => {
        if(templates) {
            dispatch(ACTION_LOADING_SPINNER_RESET())
        }
    },[templates])

    useEffect(() => {
        if(reportTemplates) {
            setTemplates(reportTemplates)
        }
    },[reportTemplates])


    // default template func
const [confirmDefaultModal, setConfirmDefaultModal] = useState(null)
      
  const [
    updateTemplateStatus,
    { data: updateTemplateStatusData },
  ] = useMutation(updateTemplateStatusMutation)

  

    const updateDefaultTemplate = (template) => {
        dispatch(ACTION_LOADING_SPINNER_ACTIVE())
        setConfirmDefaultModal(null)
        updateTemplateStatus({
            variables:{
                _id: template._id,
                type: template.type
            }
        }).then(() => {
        setTemplates(templates.map(x => {
            if(x.type == template.type) {
            if(x._id == template._id) {
            x.default = true
            return x   
            } else {
            x.default = false
            return x 
            }  
        } else {
            return x
        }                      
        }))
        })
    }
    if(!user) {
        return <DataSpinner />
    }

    return (
        <div className="adminContent">
            <ConfirmDefaultModal
                deleteType={"Template"}
                confirmDefaultModal={confirmDefaultModal}
                setConfirmDefaultModal={setConfirmDefaultModal}
                updateDefaultTemplate={updateDefaultTemplate}
            />
                {returnToHomeScreen()}
            <div className="auditAreasContainer">
                {templateTypes.map((type, i) => (
                <div key={i} className={`${type.expanded ? "auditExpanded auditReports": "auditReports"}`}>
                <div style={{display:"flex", justifyContent:"space-between"}} className="userSquareHeader">
                    <div style={{
                      display:"flex",
                      justifyContent:"center",
                      alignItems:"center"
                    }}>
                    <span>{type.type}</span>
                    </div> 
                    <FontAwesomeIcon className={`${type.expanded ? "collapseArrow collapseActive" : "collapseArrow"}`} onClick={() => {
                        type.expanded = !type.expanded
                        setTemplateTypes(templateTypes.map(ar => ar.type == type.type ? type : ar))
                      }}  icon={faChevronDown}/> 
                </div>
                <div className={`${type.expanded ? "areaContainerOn" : "areaContainerOff"}`}>
                <div className="templatesContainer">
                { templates ? 
                  templates.filter((templateType) => templateType.type == type.type).length > 0 ?
                  templates.filter((templateType) => templateType.type == type.type).map((template, i) => (
                      <div key={i} className="templateContainerParent" style={{opacity: template.default ? "1" : ".5"}}>
                      <div className="templatecontainer">
                        <div 
                        style={{display:"flex", justifyContent:"center", alignItems:"center"}}
                        onClick={() => {
                            history.push({
                                pathname: `/admin/reports/template`,
                                state: {
                                type: template.type,
                                _id: template._id,
                                html: template.html,
                                default: template.default,
                                css: template.css,
                                history: template.history
                                }
                            })
                        }} className="templateThumbnail"
                        >
                    <span style={{fontWeight:"bold"}}>{template.name}</span>

                        </div>
                        <div className="templateHeader">
                           <span>Default:</span> 
                           <div className="templateDefault">
                        <FormGroup check>
                        <Label check>
                            <Input
                                type="checkbox"
                                value={template.default}
                                checked={template.default}
                                onChange={(e) => !template.default && setConfirmDefaultModal(template)}
                            />
                        </Label>
                        </FormGroup>
                        </div>
                        </div>
                    </div>
                    </div>
                  )) : (
                      <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center", marginBottom:"1rem"}}>
                    <div className="tableHeaderAdd">
                        <div 
                            style={{
                                color: "rgb(255, 255, 255)",
                                pointerEvents:  "auto",
                                background: "#5C92BF",
                                
                                marginRight: "1rem",
                                fontSize:"14",
                                fontWeight:"bold"
                                }} 
                                className="auth-button addUser"
                                onClick={() => history.push({
                                    pathname: `/admin/reports/template`,
                                    state: {
                                    type: type,
                                    _id: null,
                                    html: null,
                                    css: null
                                    }
                                })}
                                >
                                Add New Template <img style={{ marginLeft:".5rem"}} src={add} />
                        </div>
                    </div>
                    </div>
                  ) : <DataSpinner small="l" />}
            </div>
                { templates &&
                  templates.filter((templateType) => templateType.type == type.type).length > 0 && <div className="tableHeaderAdd">
                    <div 
                        style={{
                            color: "rgb(255, 255, 255)",
                            pointerEvents:  "auto",
                            background: "#5C92BF",
                            
                            marginRight: "1rem",
                            fontSize:"14",
                            fontWeight:"bold"
                            }} 
                            className="auth-button addUser"
                            onClick={() => history.push({
                                pathname: `/admin/reports/template`,
                                state: {
                                type: type,
                                _id: null,
                                html: null,
                                css: null
                                }
                            })}
                            >
                            Add New Template <img style={{ marginLeft:".5rem"}} src={add} />
                    </div>
                </div>}
            </div>
            </div>
              ))}
        </div>
        </div>
    )
}

export default ReportTemplates