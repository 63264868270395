import { gql } from "apollo-boost";

const allUsersQuery = gql`
  query(
    $role: String
    $_id: ID
  ) {
    allUsers(
    role: $role
    _id: $_id
     
    ) {
      _id
      name
      email
      phoneNumber
      zip
      status
      wmg
      adminType
      letter
      active
      role
      image
      certificateExpiry
      certificate
      companyName
      companyAddress
      franchiseStatus
      franchiseTerritory
      licenseNumber
      owner
      userRole
      client
      surface
      pendulum
    }
  }
`;

export default allUsersQuery;