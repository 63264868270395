// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentContainer {
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 100%;
}

.sc-dlVxhl.hkMDrI {
  height: 100%;
}

.ddDjnO {
  min-width: 50px !important;
}

.rdt_TableHeadRow {
  font-size: 11px;
  font-weight: 700;
  color: #505463;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.rdt_TableRow {
  cursor: pointer;
}

.borderHover {
  padding: 0.5rem 2.5rem 0.5rem 0.3rem;
  border-radius: 4px;
}

.bsgpsK div:first-child {
  white-space: pre-wrap !important;
  overflow: visible !important;
}

.lnndaO {
  white-space: pre-wrap !important;
  overflow: visible !important;
  font-size: 10px;
}

.preW {
  display: -webkit-box;
  max-width: 250px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hkMDrI::-webkit-scrollbar {
  display: none;
}

.kUKiTL {
  padding: 0px !important;
  margin: 0 4px;
}

.bVYdcq {
  padding: 0px !important;
  margin: 0 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/resuable/accountTable/tableContent/tableContent.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,mBAAA;EACA,YAAA;AACF;;AAEA;EACA,YAAA;AACA;;AAEA;EACC,0BAAA;AACD;;AACA;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,yBAAA;EACA,qBAAA;AAEF;;AACA;EACE,eAAA;AAEF;;AAMA;EACE,oCAAA;EACA,kBAAA;AAHF;;AAaA;EACE,gCAAA;EACA,4BAAA;AAVF;;AAYA;EACE,gCAAA;EAEA,4BAAA;EACA,eAAA;AAVF;;AAYA;EACE,oBAAA;EACA,gBAAA;EACA,qBAAA;EACA,4BAAA;EACA,gBAAA;EACA,uBAAA;AATF;;AAWA;EACE,aAAA;AARF;;AAUA;EACE,uBAAA;EACA,aAAA;AAPF;;AASA;EACE,uBAAA;EACA,aAAA;AANF","sourcesContent":[".contentContainer {\n  margin-top: 1rem;\n  margin-bottom: 1rem;\n  height: 100%;\n\n}\n.sc-dlVxhl.hkMDrI {\nheight: 100%;\n}\n\n.ddDjnO {\n min-width: 50px !important;\n}\n.rdt_TableHeadRow {\n  font-size: 11px;\n  font-weight: 700;\n  color: #505463;\n  text-transform: uppercase;\n  letter-spacing: 0.5px;\n  // line-height: 13px;\n}\n.rdt_TableRow {\n  cursor: pointer;\n// transition: background-color .25s;\n// &:hover {\n//   background-color: #F2F5FF;\n//   transition: background-color .25s;\n// }\n}\n\n.borderHover {\n  padding: .5rem 2.5rem .5rem .3rem;\n  border-radius: 4px;\n  \n  // &:hover {\n  // border: 1px solid #C5C5C5;\n  // padding: .5rem 2.5rem .5rem .3rem;\n  // background-color: white;\n  // width: 100%;\n  \n  // }\n}\n.bsgpsK div:first-child {\n  white-space: pre-wrap !important;\n  overflow: visible !important;\n}\n.lnndaO {\n  white-space: pre-wrap !important;\n\n  overflow: visible !important;\n  font-size: 10px;\n}\n.preW {\n  display: -webkit-box;\n  max-width: 250px;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n.hkMDrI::-webkit-scrollbar {\n  display: none;\n}\n.kUKiTL {\n  padding: 0px !important;\n  margin: 0 4px;\n}\n.bVYdcq {\n  padding: 0px !important;\n  margin: 0 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
