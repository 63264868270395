// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addUser {
  margin-top: 0rem !important;
  width: 140px;
  height: 32px;
  font-size: 12px;
}

@media screen and (max-width: 900px) {
  .floatButton {
    position: fixed;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 10px;
    border-radius: 50%;
    width: 50px !important;
    height: 50px !important;
    z-index: 2;
  }
  .floatButton span {
    display: none;
  }
  .floatButton svg {
    margin-left: 0px !important;
    font-size: 1rem;
  }
}

.searchUser {
  width: 326px;
  height: 36px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative !important;
}
@media screen and (max-width: 900px) {
  .searchUser {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .tableHeaderSearch {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/resuable/accountTable/tableHeader/tableHeader.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;AACJ;;AACA;EACI,2BAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;AAEJ;;AAEI;EADJ;IAEQ,eAAA;IACA,WAAA;IACA,aAAA;IACA,uBAAA;IACA,mBAAA;IACA,YAAA;IACA,kBAAA;IACA,sBAAA;IACA,uBAAA;IACA,UAAA;EAEN;EADM;IACC,aAAA;EAGP;EADM;IACI,2BAAA;IACA,eAAA;EAGV;AACF;;AAAA;EACI,YAAA;EACA,YAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,6BAAA;AAGJ;AAFI;EARJ;IASQ,WAAA;EAKN;AACF;;AADI;EADJ;IAEQ,WAAA;EAKN;AACF","sourcesContent":[".tableHeader {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n.addUser {\n    margin-top: 0rem !important;\n    width: 140px;\n    height: 32px;\n    font-size: 12px;\n\n}\n.floatButton {\n    @media screen and (max-width : 900px) {\n        position: fixed;\n        right: 10px;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        bottom: 10px;\n        border-radius: 50%;\n        width: 50px !important;              \n        height: 50px !important;\n        z-index: 2;\n        span {\n         display: none;   \n        }\n        svg {\n            margin-left: 0px !important;\n            font-size: 1rem;\n        }\n    }\n}\n.searchUser {\n    width: 326px;\n    height: 36px;\n    font-size: 12px;\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    position: relative !important; \n    @media screen and (max-width : 900px) {\n        width: 100%;\n    }\n    \n}\n.tableHeaderSearch {\n    @media screen and (max-width : 900px) {\n        width: 100%;\n    } \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
