// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableTabs {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
}

.tabsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.accountTabs {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.4rem;
  padding: 0 1rem;
  margin: 0 0.5rem;
  border-bottom: 2px solid transparent;
  color: #8C9299;
}

.tabElement {
  margin: 0 0.2rem;
  cursor: pointer;
}

.tabCount {
  border-radius: 7px;
  height: 22px;
  width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: rgba(92, 146, 191, 0.5019607843);
  font-size: 10px;
}

.activeTab {
  border-bottom: 3px solid #5E6266;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/components/resuable/accountTable/tableTabs/tableTabs.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,WAAA;EACA,gBAAA;AACJ;;AACA;EACI,aAAA;EACA,mBAAA;EACA,2BAAA;AAEJ;;AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,oCAAA;EACA,cAAA;AAGJ;;AADA;EACI,gBAAA;EACA,eAAA;AAIJ;;AADA;EACI,kBAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,kDAAA;EACA,eAAA;AAIJ;;AADA;EACI,gCAAA;EACA,YAAA;AAIJ","sourcesContent":[".tableTabs {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    margin-top: 1rem;\n}\n.tabsContainer {\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n}\n.accountTabs {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 2.4rem;\n    padding: 0 1rem;\n    margin: 0 .5rem;\n    border-bottom: 2px solid transparent;\n    color: #8C9299;\n}\n.tabElement {\n    margin: 0 .20rem;\n    cursor: pointer;\n\n}\n.tabCount {\n    border-radius: 7px;\n    height: 22px;\n    width: 22px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: white;\n    background-color: #5C92BF80;\n    font-size: 10px;\n\n}\n.activeTab {\n    border-bottom: 3px solid #5E6266;\n    color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
